import { createContext, useContext, useState } from "react";

const SkillsFormContext = createContext(null);

/**
 * Multi-step form state provider for skills questionnaire.
 */
export const SkillsFormProvider = ({ children }) => {
  const [state, setState] = useState(() => {});
  const get = (key) => {
    return state[key];
  };
  const set = (key, value) => {
    setState((state) => ({
      ...state,
      [key]: value,
    }));
  };
  const del = (key) => {
    setState((state) => ({
      ...state,
      [key]: undefined,
    }));
  };
  return (
    <SkillsFormContext.Provider value={{ get, set, del }}>{children}</SkillsFormContext.Provider>
  );
};

export const useSkillsFormContext = () => {
  return useContext(SkillsFormContext);
};
