import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: `jobook@${process.env.REACT_APP_VERSION}`,
  environment: `pwa-client-${process.env.REACT_APP_SENTRY_ENV}`,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

if(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE);
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
