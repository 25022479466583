const jobAdsCanApply = (candidate, data) => {
  const jobAdsSchoolsArray = [];
  for (let i = 0; i < candidate?.schoolUUIDs?.length; i++) {
    jobAdsSchoolsArray.push(
      data?.filter((x) => x?.schoolUuids?.some((y) => y === candidate?.schoolUUIDs[i]) || x?.schools?.some((y) => y.uuid === candidate?.schoolUUIDs[i]) )
    );
  }

  jobAdsSchoolsArray.push(data.filter((x) => x.jobAdType === "MULTIPOSTED"));

  const jobAdsSchools = jobAdsSchoolsArray.flat();
  return jobAdsSchools;
};

export default jobAdsCanApply;
