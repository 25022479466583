import requireContext from "require-context.macro";
import { useJobAdOrganizationName } from "../common/useJobAdOrganizationName";

const slideCache = {};

function importAll(r) {
  r.keys().forEach((key) => {
    const [name] = key.substring(2).split("/");
    slideCache[name] = slideCache[name] || [];
    slideCache[name].push(r(key));
  });
}

importAll(requireContext("./slides", true, /\.(jpg|png|jpeg)$/));

export const useSlides = () => {
  const organizationName = useJobAdOrganizationName();
  // Choose domain-specific slides if they exist
  const slides = slideCache[organizationName] || slideCache[process.env.REACT_APP_SLIDES];
  // Load modules
  return slides.map((slide) => slide.default);
};
