import { createContext, useContext, useReducer } from "react";
import { getStoredAuthentication } from "./AuthenticationAPI";

/** @typedef {{ token: string | null }} State */
/** @typedef {{ type: string, payload?: any }} Action */

/** @type {React.Context<State>} */
const AuthenticationStateContext = createContext();
/** @type {React.Context<React.Dispatch<Action>>} */
const AuthenticationDispatchContext = createContext();

const LOGIN = "authentication/LOGIN";
/** @type {(payload: State) => Action} */
export function doLogin(payload) {
  return { type: LOGIN, payload };
}

const LOGOUT = "authentication/LOGOUT";
/** @type {() => Action} */
export function doLogout() {
  return { type: LOGOUT };
}

const initialState = getStoredAuthentication();

/**
 * Authentication reducer
 * @type {(state: State, action: Action) => State}
 */
function reducer(state, action) {
  switch (action.type) {
    case LOGIN:
      return {
        token: action.payload.token,
      };
    case LOGOUT:
      return {
        token: null,
      };
    default:
      return state;
  }
}

export function AuthenticationProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <AuthenticationStateContext.Provider value={state}>
      <AuthenticationDispatchContext.Provider value={dispatch}>
        {children}
      </AuthenticationDispatchContext.Provider>
    </AuthenticationStateContext.Provider>
  );
}

/** @type {() => State} */
export function useAuthenticationState() {
  const state = useContext(AuthenticationStateContext);
  if (state === undefined && process.env.NODE_ENV === "production") {
    throw new Error(
      `\`${useAuthenticationState.name}\` should be wrapped in a \`${AuthenticationProvider.name}\`.`
    );
  }
  return state;
}

/**
 * Hook to retrieve current access token.
 * @type {() => string | null}
 */
export function useAccessToken() {
  const { token } = useAuthenticationState();
  return token;
}

/**
 * Hook to check if user is authenticated.
 * @type {() => boolean}
 */
export function useAuthenticated() {
  const token = useAccessToken();
  return !!token;
}

export function Authenticated({ children = null, fallback = null }) {
  const authenticated = useAuthenticated();
  return authenticated ? children : fallback;
}

export function NotAuthenticated({ children = null, fallback = null }) {
  const authenticated = useAuthenticated();
  return !authenticated ? children : fallback;
}

/** @type {() => React.Dispatch<Action>} */
export function useAuthenticationDispatch() {
  const dispatch = useContext(AuthenticationDispatchContext);
  if (dispatch === undefined && process.env.NODE_ENV === "production") {
    throw new Error(
      `\`${useAuthenticationDispatch.name}\` should be wrapped in a \`${AuthenticationProvider.name}\`.`
    );
  }
  return dispatch;
}
