import ky from "ky";
import { useCallback, useMemo } from "react";
import {
  getStoredAuthentication,
  storeAuthentication,
  useLogout,
} from "../authentication/AuthenticationAPI";

/** @type {() => Promise<{ token: string }>} */
const useRefreshToken = () => {
  const refreshToken = useCallback(async () => {
    const { token } = getStoredAuthentication();
    const result = await ky
      .post(`${process.env.REACT_APP_CANDIDATE_API_URL}/public/candidate/renew`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .json();
    storeAuthentication(result);
    return result;
  }, []);
  return refreshToken;
};

/** @type {(request: Request) => void} */
const addCredentialsToRequest = (request) => {
  const { pathname } = new URL(request.url);
  if (pathname.startsWith("/public/")) {
    // Don't authenticate public API requests
    return;
  }
  if (pathname.startsWith("/api/")) {
    // Authenticate private API requests
    const { token } = getStoredAuthentication();
    request.headers.set("Authorization", `Bearer ${token}`);
  }
};

const useAPI = () => {
  const logout = useLogout();
  const refreshToken = useRefreshToken();
  return useMemo(
    () =>
      ky.create({
        retry: {
          // FIXME: API currently responds with 500 for validation errors
          // this removes 500 from retry codes
          statusCodes: [401, 408, 413, 429, 502, 503, 504],
        },
        timeout: false,
        hooks: {
          beforeRequest: [
            (request) => {
              addCredentialsToRequest(request);
            },
          ],
          beforeRetry: [
            async ({ request, error }) => {
              const { pathname } = new URL(request.url);
              if (error?.response?.status === 401 && pathname.startsWith("/api/")) {
                const errorPayload = await error.response.json();
                if (errorPayload.message === "EXPIRED_TOKEN") {
                  try {
                    await refreshToken();
                  } catch (error) {
                    // Refresh failed, logout
                    await logout();
                    return ky.stop;
                  }
                  addCredentialsToRequest(request);
                  return;
                }
                // Authentication is invalid, logout
                await logout();
                return ky.stop;
              }
              return;
            },
          ],
        },
      }),
    [logout, refreshToken]
  );
};

export const useMediaAPI = () => {
  const api = useAPI();
  return useMemo(() => {
    return api.extend({
      prefixUrl: process.env.REACT_APP_MEDIA_API_URL,
    });
  }, [api]);
};

export const useCandidateAPI = () => {
  const api = useAPI();
  return useMemo(() => {
    return api.extend({
      prefixUrl: process.env.REACT_APP_CANDIDATE_API_URL,
    });
  }, [api]);
};

export const useExternalCandidateAPI = () => {
  const api = useAPI();
  return useMemo(() => {
    return api.extend({
      prefixUrl: process.env.REACT_APP_EXTERNAL_CANDIDATE_API_URL,
    });
  }, [api]);
};

export const useSkillAPI = () => {
  const api = useAPI();
  return useMemo(() => {
    return api.extend({
      prefixUrl: process.env.REACT_APP_SKILL_API_URL,
    });
  }, [api]);
};
