import { useCallback, useMemo, useState } from "react";

export const useDisclosure = (initialState) => {
  const [isOpen, setIsOpen] = useState(initialState);
  const onOpen = useCallback(() => setIsOpen(true), []);
  const onClose = useCallback(() => setIsOpen(false), []);
  const onToggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []);

  return useMemo(
    () => ({
      isOpen,
      setIsOpen,
      onOpen,
      onClose,
      onToggle,
    }),
    [isOpen, onClose, onOpen, onToggle]
  );
};
