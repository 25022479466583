import { ErrorBoundary } from "@sentry/react";
import { Suspense } from "react";
import { useJobAdOrganizationName } from "../common/useJobAdOrganizationName";
import { useLocalStorage } from "../common/useLocalStorage";
import { usePublicJobAdByIdQuery } from "../job-ads/JobAdsAPI";

const nothing = () => null;

const WelcomeMessage = ({ children = nothing, fallback }) => {
  const [fromJobAd] = useLocalStorage("from-job-ad");
  const organizationName = useJobAdOrganizationName();
  const { data: jobAd } = usePublicJobAdByIdQuery(fromJobAd);
  return jobAd && organizationName
    ? children({ jobAdTitle: jobAd.title, jobAdDomain: organizationName, jobAdCity: jobAd.location.city, jobAdContract: jobAd.contract })
    : fallback;
};

export const WelcomeMessageContainer = ({ children, fallback }) => {
  return (
    <ErrorBoundary fallback={fallback}>
      <Suspense fallback={fallback}>
        <WelcomeMessage fallback={fallback}>{children}</WelcomeMessage>
      </Suspense>
    </ErrorBoundary>
  );
};
