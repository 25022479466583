/** @jsxImportSource @emotion/react */
import { isContextualJobAd } from "../job-ads/JobAdsAPI";
/** @jsxImportSource @emotion/react */
import { FormattedSalary, isSalaryDefined } from "@jobook-io/jobook-job-card";
import { useTranslation } from "react-i18next";
import requireContext from "require-context.macro";
import "twin.macro";
import tw from "twin.macro";
import { dynamicResource } from "../common/dynamicResource";
import { useCandidateAuthentication } from "./UnauthenticatedApplyAPI";

const entrepriseSvg = dynamicResource(requireContext("../job-ads", false, /entreprise(.*)\.svg$/));
const contratSvg = dynamicResource(requireContext("../job-ads", false, /contrat(.*)\.svg$/));
const experienceSvg = dynamicResource(requireContext("../job-ads", false, /experience(.*)\.svg$/));
const locationSvg = dynamicResource(requireContext("../job-ads", false, /location(.*)\.svg$/));
const salaireSvg = dynamicResource(requireContext("../job-ads", false, /salaire(.*)\.svg$/));
const tempsSvg = dynamicResource(requireContext("../job-ads", false, /temps(.*)\.svg$/));
const startSvg = dynamicResource(requireContext("../job-ads", false, /start(.*)\.svg$/));

const JobAdCardListItem = ({ icon, text, color = "gray", bold = false, hiddenmobile = false }) => {
  return (
    <div tw="flex bg-white items-center w-10/12 rounded-2xl mt-2"
      css={[
        hiddenmobile ? tw`hidden` : tw`flex`
      ]}>
      <div tw="flex-initial w-[10%] m-2">
        <img src={icon} />
      </div>
      <div tw="flex-initial w-full text-left"
        css={[
          color === "black" ? tw`text-black` : tw`text-gray-500`,
          bold && tw`font-semibold`
        ]}>
        {text}
      </div>
    </div>
  )
}

export const JobAdCardContent = ({ jobAd, isBackgroungDisplayed = true }) => {
  const { t } = useTranslation();
  const candidateAuthentication = useCandidateAuthentication();
  let contractText = "CDI"
  if (jobAd.contract) {
    contractText = t(`contract-types.${jobAd.contract}`);
    if (["CDD", "INTERIM", "INTERN", "FREELANCER", "APPRENTICE"].includes(jobAd.contract)) {
      contractText += " (" + t("job-ads.contract-duration-in-months", { count: jobAd.durationInMonth }) + ")"
    }
  }
  let startYear = null, startMonth = null, startDay = null;
  if (jobAd.startDate !== null) [startYear, startMonth, startDay] = jobAd.startDate.split('-');
  let salaryText = "";
  if (isSalaryDefined(jobAd.remuneration)) {
    let frequencyText = ""
    let currency = "€";
    if (jobAd.remuneration.currency === "USD") {
      currency = "$";
    } else if (jobAd.remuneration.currency === "GBP") {
      currency = "£";
    }
    if (jobAd.remuneration.period) {
      frequencyText = t(`job-ads.frequency-${jobAd.remuneration.period}`);
    }
    if (jobAd.remuneration.min && jobAd.remuneration.max) {
      if (jobAd.remuneration.min === jobAd.remuneration.max) {
        salaryText = t(`job-ads.salary-from`, { from: `${jobAd.remuneration.min} ${currency}`, frequency: frequencyText })
      } else {
        salaryText = t(`job-ads.salary-from-to`, { from: `${jobAd.remuneration.min} ${currency}`, to: `${jobAd.remuneration.max} ${currency}`, frequency: frequencyText })
      }
    } else if (jobAd.remuneration.min && !jobAd.remuneration.max) {
      salaryText = t(`job-ads.salary-from`, { from: `${jobAd.remuneration.min} ${currency}`, frequency: frequencyText })
    } else if (!jobAd.remuneration.min && jobAd.remuneration.max) {
      salaryText = t(`job-ads.salary-to`, { to: `${jobAd.remuneration.max} ${currency}`, frequency: frequencyText })
    }
  }

  return (
    <>
      {/* <img tw="absolute w-full h-full object-cover" src={image} alt="" /> */}
      {/* Overlay */}
      {isBackgroungDisplayed && <div tw="absolute inset-0 bg-[#667af8]" />}

      {jobAd.source === 'poleemploi' && (
        <div tw="absolute right-0">
          <dt tw="animate-pulse my-2 mx-4 text-sm text-gray-200">
            {t(`job-ads.external-jobad`)}
          </dt>
        </div>
      )}
      {/* Header */}
      <header tw="top-1/2 left-0 right-0 px-6 py-5 flex flex-col items-center text-center transform">
        {!isContextualJobAd(jobAd) && (
          <div
            tw="inline-flex w-14 h-14 items-center justify-center rounded-full text-gray-800 text-base font-medium bg-gray-100 mx-auto"
            css={[
              jobAd.globalMatchingScore <= 70 &&
              jobAd.globalMatchingScore > 50 &&
              tw`text-white bg-jobfirstyellow`,
              jobAd.globalMatchingScore > 70 && tw`text-white bg-jobfirstgreen`,
            ]}
          >
            <div tw="absolute left-0">
              <dt tw="my-2 ml-16 sm:ml-32 text-sm text-gray-200">
                {t(`job-ads.for-you`)}
              </dt>
            </div>
            <dt tw="sr-only">{t("job-ads.matching")}</dt>
            <dd tw="text-xl font-bold">{jobAd.globalMatchingScore}%</dd>
          </div>
        )}
        <h3 tw="font-bold leading-7 text-white block">{t("welcome.youAreGoingToApplyToJobAd")}</h3>
        <h3 tw="text-2xl font-bold leading-7 text-white mt-2 mb-4">{jobAd.title}</h3>
        <JobAdCardListItem icon={entrepriseSvg} text={jobAd.organizationName} color="black" bold={true} />
        {jobAd.location && jobAd.location.address && (
          <JobAdCardListItem icon={locationSvg} text={t(`job-ads.at-location`, { address: jobAd.location.address })} bold={true} />
        )}
        {jobAd.contract && (
          <JobAdCardListItem
            icon={contratSvg}
            text={contractText}
            bold={true}
          />
        )}
        {isSalaryDefined(jobAd.remuneration) && (
          <JobAdCardListItem
            icon={salaireSvg}
            text={salaryText}
            bold={true}
          />
        )}
        {jobAd.employmentType && (
          <JobAdCardListItem
            icon={tempsSvg}
            text={t(`employment-types.${jobAd.employmentType}`)}
            bold={true}
          />
        )}
        {jobAd.yearsOfExperience !== null && (
          <JobAdCardListItem
            icon={experienceSvg}
            text={jobAd.yearsOfExperience === 0
              ? t("job-ads.zero-years-of-experience")
              : t("job-ads.years-of-experience-value", { count: jobAd.yearsOfExperience })
            }

            bold={true}
            hiddenmobile={"MOBILE" === candidateAuthentication}
          />
        )}
        {jobAd.startDate !== null && (
          <JobAdCardListItem
            icon={startSvg}
            text={`${startDay}/${startMonth}/${startYear}`}
            bold={true}
            hiddenmobile={"MOBILE" === candidateAuthentication}
          />
        )}
      </header>
    </>
  );
};

