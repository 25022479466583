/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { useEffect } from "react";
import "twin.macro";
import tw from "twin.macro";
import { usePrimaryNavigationDisclosure } from "./PrimaryNavigation";

export const Card = styled("section")(
  tw`w-full shadow-xl rounded-t-3xl sm:(rounded-b-3xl) flex-1 sm:(flex-none) bg-white text-gray-800 px-4 py-5`
);

export const FullscreenCard = styled(Card)(tw`px-8 py-28 flex-none sm:(mb-64 px-16 py-20)`);

export const CardTitle = styled("h1")(tw`text-xl leading-6 font-semibold text-gray-900 text-center`);
export const CardDescription = styled("p")(tw`mt-4 text-base text-gray-500 text-center`);

// PrimaryNavigation is at z-index = 2000, Card actions go above it
const CardActionsWrapper = styled("footer")(
  tw`fixed bottom-0 left-0 right-0 overflow-hidden z-index[3000]`,
  tw`sm:(relative bottom-auto left-auto right-auto overflow-visible z-index[auto])`
);

const AnimatedCardActions = styled("div")(
  tw`flex flex-col items-center justify-center min-height[64px] p-2 bg-white shadow-lg border-gray-200 border-t transition-transform transform`,
  tw`sm:(bg-transparent shadow-none border-t-0 min-height[auto] p-0 pt-6)`,
  ({ isOpen }) => (isOpen ? tw`translate-y-0` : tw`translate-y-full`),
  tw`sm:(translate-y-0)`
);

const CardActions = (props) => {
  const { isOpen, onOpen, onClose } = usePrimaryNavigationDisclosure();
  useEffect(() => {
    if (props.isOpen === false) {
      // If floating CardActions are force to be hidden, do nothing
      return;
    }
    let animationFrame = null;
    if (isOpen) {
      // If primary navigation is visible, hide it
      animationFrame = requestAnimationFrame(() => {
        // Wait a frame before changing the primary navigation state,
        // this allows the card actions to transition from hidden to visible
        onClose();
      });
    }
    return () => {
      if (animationFrame !== null) {
        cancelAnimationFrame(animationFrame);
      }
      if (!isOpen) {
        // If primary navigation is not visible, show it
        onOpen();
      }
    };
  }, [isOpen, onClose, onOpen, props.isOpen]);
  return (
    <CardActionsWrapper>
      <AnimatedCardActions isOpen={!isOpen} {...props} />
    </CardActionsWrapper>
  );
};

Card.Actions = CardActions;
