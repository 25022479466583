/** @jsxImportSource @emotion/react */
import { css, Global } from "@emotion/react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import tw from "twin.macro";
import { LogoLight } from "../common/LogoLight";
import { useSlides } from "./useSlides";
import { WelcomeMessageContainer } from "./WelcomeMessage";


const Heading = styled("h2")(
  tw`text-center text-3xl font-bold text-[#585858] sm:text-4xl md:text-5xl lg:text-5xl`
);

const Heading2 = styled("h2")(
  tw`text-center text-2xl font-bold text-[#585858] sm:text-2xl md:text-3xl lg:text-3xl`
);

const Heading3 = styled("h2")(
  tw`text-center leading-6 text-xl font-semibold text-[#585858] sm:text-3xl`
);

const HeadingLight = styled("h3")(
  tw`text-center text-xl font-bold text-[#585858] sm:text-3xl md:text-3xl lg:text-2xl`
);

const SlideContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: 40,
  paddingRight: 40,
  height: "100%",
  width: "100%",
  background: "rgba(0,0,0,0)",

  paddingTop: 20,
  paddingBottom: 220,

  "@media (min-height: 640px)": {
    paddingTop: 20,
    paddingBottom: 220,
  },

  "@media (min-width: 640px)": {
    paddingLeft: 60,
    paddingRight: 60,
  },
});

export const WelcomeSlider = () => {
  const { t } = useTranslation();
  const [Welcome0, Welcome1, Welcome2] = useSlides();
  return (
    <div tw="relative overflow-hidden">
      <Global
        styles={css({
          ".slick-dots": {
            bottom: "0px !important",
            "@media (min-width: 640px)": {
              bottom: "10px !important",
            },
          },
          ".slick-dots li button:before": {
            color: `#585858!important`,
          },
          ".slick-dots li.slick-active button:before": {
            color: `#585858!important`,
          },
          ".slick-prev:before, .slick-next:before": {
            color: `#585858!important`,
          },
          ".slick-prev": {
            left: "10px!important",
            "@media (min-width: 640px)": {
              left: "20px !important",
            },
          },
          ".slick-next": {
            right: "10px!important",
            "@media (min-width: 640px)": {
              right: "20px !important",
            },
          },
          ".slick-list": {
            zIndex: -1,
          },
        })}
      />
      {process.env.REACT_APP_RESOURCE_SUFFIX === 'phd' && (
        < Slider autoplay autoplaySpeed={4300} dots slidesToShow={1} slidesToScroll={1}>
          <div>
            <div
              style={{
                backgroundImage: `url(${Welcome0})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                width: "100%",
                height: "100vh",
              }}
            >
              <SlideContent>
                <HeadingLight>
                  <WelcomeMessageContainer fallback="">
                    {(props) => t("welcome.youAreGoingToApplyToJobAd", props)}
                  </WelcomeMessageContainer>
                  <br />
                  <br />
                </HeadingLight>
                <Heading>
                  <WelcomeMessageContainer fallback={t("welcome.Slide0Title")}>
                    {(props) => t("welcome.youAreGoingToApplyToJobAd2", props)}
                  </WelcomeMessageContainer>
                  <br />
                </Heading>
                <HeadingLight>
                  <WelcomeMessageContainer fallback="">
                    {(props) => t("welcome.youAreGoingToApplyToJobAd3", props)}
                  </WelcomeMessageContainer>
                  <br />
                </HeadingLight>
                <Heading2>
                  <WelcomeMessageContainer fallback="">
                    {(props) => t("welcome.youAreGoingToApplyToJobAd4", props)}
                  </WelcomeMessageContainer>
                </Heading2>
              </SlideContent>
            </div>
          </div>
          <div>
            <div
              style={{
                backgroundImage: `url(${Welcome1})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                width: "100%",
                height: "100vh",
              }}
            >
              <SlideContent>
                <Heading>
                  <WelcomeMessageContainer fallback={t("welcome.Slide1Title")}>
                    {(props) => t("welcome.pleaseCompleteYourProfile", props)}
                  </WelcomeMessageContainer>
                  <br />
                  <WelcomeMessageContainer fallback="">
                    {(props) => t("welcome.pleaseCompleteYourProfile2", props)}
                  </WelcomeMessageContainer>
                </Heading>
              </SlideContent>
            </div>
          </div>
          <div>
            <div
              style={{
                backgroundImage: `url(${Welcome2})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                width: "100%",
                height: "100vh",
              }}
            >
              <SlideContent>
                <Heading>
                  <WelcomeMessageContainer fallback={t("welcome.Slide2Title")}>
                    {(props) => t("welcome.confirmYourApplicationWithOneClick", props)}
                  </WelcomeMessageContainer>
                </Heading>
              </SlideContent>
            </div>
          </div>
        </Slider>
      )}
      {process.env.REACT_APP_RESOURCE_SUFFIX !== 'phd' && (
        < Slider autoplay autoplaySpeed={4300} dots slidesToShow={1} slidesToScroll={1}>
          <div>
            <div
              style={{
                // backgroundImage: `url(${Welcome0})`,
                backgroundSize: "cover",
                backgroundColor: "white",
                backgroundPosition: "center center",
                width: "100%",
                height: "100vh",
              }}
            >
              <SlideContent>
                {/* <img tw="max-h-52 sm:max-h-80" src={LogoSrc}></img> */}
                <img tw="max-h-40 sm:max-h-80" src="https://static.jobfirst.io/welcome/img/Welcome0.png"></img>
                <Heading3>
                  <WelcomeMessageContainer fallback="">
                    {(props) => t("welcome.youAreGoingToApplyToJobAd", props)}
                  </WelcomeMessageContainer>
                  <br />
                  <br />
                </Heading3>
                <Heading3>
                  <WelcomeMessageContainer fallback={t("welcome.Slide0Title")}>
                    {(props) => t("welcome.youAreGoingToApplyToJobAd2", props)}
                  </WelcomeMessageContainer>
                  <br />
                </Heading3>
                <Heading3>
                  <WelcomeMessageContainer fallback="">
                    {(props) => t("welcome.youAreGoingToApplyToJobAd3", props)}
                  </WelcomeMessageContainer>
                  <br />
                </Heading3>
                <Heading3>
                  <WelcomeMessageContainer fallback="">
                    {(props) => t("welcome.youAreGoingToApplyToJobAd4", props)}
                  </WelcomeMessageContainer>
                </Heading3>
              </SlideContent>
            </div>
          </div>
          <div>
            <div
              style={{
                // backgroundImage: `url(${Welcome1})`,
                backgroundSize: "cover",
                backgroundColor: "white",
                backgroundPosition: "center center",
                width: "100%",
                height: "100vh",
              }}
            >
              <SlideContent>
                <img tw="max-h-40 sm:max-h-80" src="https://static.jobfirst.io/welcome/img/Welcome1.png"></img>
                <Heading3>
                  <WelcomeMessageContainer fallback={t("welcome.Slide1Title")}>
                    {(props) => t("welcome.pleaseCompleteYourProfile", props)}
                  </WelcomeMessageContainer>
                  <br />
                  <WelcomeMessageContainer fallback="">
                    {(props) => t("welcome.pleaseCompleteYourProfile2", props)}
                  </WelcomeMessageContainer>
                </Heading3>
              </SlideContent>
            </div>
          </div>
          <div>
            <div
              style={{
                // backgroundImage: `url(${Welcome2})`,
                backgroundSize: "cover",
                backgroundColor: "white",
                backgroundPosition: "center center",
                width: "100%",
                height: "100vh",
              }}
            >
              <SlideContent>
                <img tw="max-h-40 sm:max-h-80" src="https://static.jobfirst.io/welcome/img/Welcome2.png"></img>
                <Heading3>
                  <WelcomeMessageContainer fallback={t("welcome.Slide2Title")}>
                    {(props) => t("welcome.confirmYourApplicationWithOneClick", props)}
                  </WelcomeMessageContainer>
                </Heading3>
              </SlideContent>
            </div>
          </div>
        </Slider>
      )}
      <div tw="flex items-center justify-center space-x-8 absolute left-0 right-0 top-4">
        <LogoLight tw="flex-shrink-0 h-16 w-auto sm:invisible" />
      </div>
    </div >
  );
};
