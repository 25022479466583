import { useEffect } from "react";
import { useHistory } from "react-router-dom";

/**
 * This component forces "scroll to top" when navigation routes using React Router DOM.
 * However, when "previous" / "back" button are used, browser scroll restoration will still work.
 */
export default function ScrollToTop() {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
}
