/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import requireContext from "require-context.macro";
import "twin.macro";
import { AspectRatioPreserver } from "./AspectRatioPreserver";
import { Button, PrimaryButton } from "./Button";
import { dynamicResource } from "./dynamicResource";

const NoConnectionIllustration = dynamicResource(
  requireContext(".", false, /NoConnectionIllustration(.*)\.svg$/)
);

export const OfflineState = ({ onRetry }) => {
  const { t } = useTranslation();
  return (
    <div tw="flex flex-col items-center justify-center w-full max-w-sm mx-auto space-y-4">
      <AspectRatioPreserver aspectRatio={880 / 774}>
        <img src={NoConnectionIllustration} alt="" />
      </AspectRatioPreserver>
      <p tw="text-sm font-medium text-gray-900 text-center">{t("common.NetworkError")}</p>
      <p tw="text-sm text-gray-500 text-center">{t("common.NetworkErrorDescription")}</p>
      <div tw="flex justify-center space-x-2">
        <Button as={Link} to="/home">
          {t("common.GoBackToHome")}
        </Button>
        <PrimaryButton onClick={onRetry}>{t("common.Refresh")}</PrimaryButton>
      </div>
    </div>
  );
};
