/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

export const Alert = ({ children, variant, ...props }) => {
  return (
    <div
      tw="rounded-md bg-yellow-50 p-4 flex text-sm font-medium"
      css={[
        variant === "success" && tw`bg-green-50 text-green-800`,
        variant === "error" && tw`bg-red-50 text-red-800`,
        variant === "info" && tw`bg-blue-50 text-blue-800`,
      ]}
      {...props}
    >
      <div
        tw="flex-shrink-0 svg:(h-5 w-5) mr-3"
        css={[
          variant === "success" && tw`svg:(text-green-400)`,
          variant === "error" && tw`svg:(text-red-400)`,
          variant === "info" && tw`svg:(text-blue-400)`,
        ]}
      >
        {variant === "success" && (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
        )}
        {variant === "error" && (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
        )}
        {variant === "info" && (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </div>
      {children}
    </div>
  );
};

Alert.defaultProps = {
  variant: "error",
};
