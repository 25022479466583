// @ts-check
import { HTTPError } from "ky";
import { useQuery, useQueryClient } from "react-query";
import { useSkillAPI } from "../query/ky";

/**
 * @typedef CloudSearchHit
 * @property {string} code The job code (e.g.: "D130100")
 * @property {string} id The job id
 * @property {string} name The job name
 * @property {string} libelleen
 * @property {string} libellees
 * @property {string} libellefr
 * @property {string} libellept
 */

const cloudSearchHitTitleMapping = {
  FR: "libellefr",
  EN: "libelleen",
  DE: "libellede",
  NL: "libellenl",
  ES: "libellees",
  IT: "libelleit",
  PT: "libellept",
};

/** @type {(cloudSearchHit: CloudSearchHit, language: import("../common/useLanguage").Language) => string} */
const resolveCloudSearchHitTitle = (cloudSearchHit, language) => {
  const property = cloudSearchHitTitleMapping[language];
  return cloudSearchHit[property] || cloudSearchHit.libelleen;
};

/** @typedef {{ input: string, lang: import("../common/useLanguage").Language}} JobsSearchParams */

/** @type {() => (searchParams: JobsSearchParams) => import("react-query").UseQueryOptions<CloudSearchHit[], Error>} */
const useCreateJobsQueryOptions = () => {
  const skillAPI = useSkillAPI();
  return (searchParams) => {
    return {
      queryKey: ["public/skill/jobesautocomplete", searchParams],
      queryFn: async () => {
        try {
          const jobs = await skillAPI
            .get("public/skill/jobesautocomplete", { searchParams })
            .json();
          // Translate job name using current language
          return jobs.map((job) => ({
            ...job,
            name: resolveCloudSearchHitTitle(job, searchParams?.lang || "EN"),
          }));
        } catch (error) {
          // API does not always handle input strings correctly (e.g.: "/", accents, etc.)
          // We try to intercepts these errors and we reset suggestions in this case.
          if (error instanceof HTTPError && error.response.status === 500) {
            const response = await error.response.json();
            if (response.message.startsWith("Error while searching with input String")) {
              return [];
            }
          }
          throw error;
        }
      },
      keepPreviousData: true,
      suspense: false,
    };
  };
};

/** @type {(searchParams: JobsSearchParams) => import("react-query").UseQueryResult<CloudSearchHit[], Error>} */
export const useFetchJobsQuery = (searchParams) => {
  const createJobsQueryOptions = useCreateJobsQueryOptions();
  return useQuery(createJobsQueryOptions(searchParams));
};

/** @type {() => (searchParams: JobsSearchParams) => Promise<CloudSearchHit[]>} */
export const useFetchJobs = () => {
  const queryClient = useQueryClient();
  const createJobsQueryOptions = useCreateJobsQueryOptions();
  return (searchParams) => queryClient.fetchQuery(createJobsQueryOptions(searchParams));
};

/** @type {() => (jobCode: string) => Promise<Job | null>} */
export const useFetchJobByCode = () => {
  const skillAPI = useSkillAPI();
  const queryClient = useQueryClient();
  return async (jobCode) => {
    return queryClient.fetchQuery({
      queryKey: `api/skill/candidate/job/byCode/${jobCode}`,
      queryFn: async () => skillAPI.get(`api/skill/candidate/job/byCode/${jobCode}`).json(),
    });
  };
};

/**
 * @typedef Job
 * @property {string} code - e.g.: "F170312"
 * @property {number} id - e.g.: 3228
 * @property {string} libelleDe - e.g.: null
 * @property {string} libelleEn - e.g.: "Bricklayer / Mason"
 * @property {string} libelleEs - e.g.: "Albañil"
 * @property {string} libelleFr - e.g.: "Maçon / Maçonne"
 * @property {string} libelleIt - e.g.: null
 * @property {string} libelleNl - e.g.: null
 * @property {string} libellePt - e.g.: "Tijolo / Pedreiro"
 * @property {string} name - e.g.: "Maçon / Maçonne"
 * @property {number} priority - e.g.: 2000
 * @property {number} specialityId - e.g.: 171
 */

const jobTitleMapping = {
  FR: "libelleFr",
  EN: "libelleEn",
  DE: "libelleDe",
  NL: "libelleNl",
  ES: "libelleEs",
  IT: "libelleIt",
  PT: "libellePt",
};

/** @type {(job: Job, language: import("../common/useLanguage").Language) => string} */
export const resolveJobTitle = (job, language) => {
  const property = jobTitleMapping[language];
  return job[property] || job.libelleEn || job.name;
};

const jobSkillNameMapping = {
  FR: "libelleFr",
  EN: "libelleEn",
  DE: "libelleDe",
  NL: "libelleNl",
  ES: "libelleEs",
  IT: "libelleIt",
  PT: "libellePt",
};

const resolveSkillName = (jobSkill, language) => {
  const property = jobSkillNameMapping[language];
  return jobSkill[property] || jobSkill.libelleEn;
};

/** @type {() => (jobSkillId: number) => Promise<Activity | null>} */
const useFetchJobSkillById = () => {
  const skillAPI = useSkillAPI();
  const queryClient = useQueryClient();
  return async (jobSkillId) => {
    return queryClient.fetchQuery({
      queryKey: `api/skill/candidate/skill/${jobSkillId}`,
      queryFn: async () => skillAPI.get(`api/skill/candidate/skill/${jobSkillId}`).json(),
    });
  };
};

/** @type {() => (skills: Activity[], language: import("../common/useLanguage").Language) => Promise<Activity[]>} */
export const useTranslateJobSkillNames = () => {
  const fetchJobSkillById = useFetchJobSkillById();
  return async (skills, language) => {
    return Promise.all(
      skills.map(async (skill) => ({
        ...skill,
        name: resolveSkillName(await fetchJobSkillById(skill.id), language),
      }))
    );
  };
};

/** @type {() => (jobActivityId: number) => Promise<Activity | null>} */
const useFetchJobActivityById = () => {
  const skillAPI = useSkillAPI();
  const queryClient = useQueryClient();
  return async (jobActivityId) => {
    return queryClient.fetchQuery({
      queryKey: `api/skill/candidate/activity/${jobActivityId}`,
      queryFn: async () => skillAPI.get(`api/skill/candidate/activity/${jobActivityId}`).json(),
    });
  };
};

/** @type {() => (activities: Activity[], language: import("../common/useLanguage").Language) => Promise<Activity[]>} */
export const useTranslateJobActivityNames = () => {
  const fetchJobActivityById = useFetchJobActivityById();
  return async (activities, language) => {
    return Promise.all(
      activities.map(async (activity) => ({
        ...activity,
        name: resolveSkillName(await fetchJobActivityById(activity.id), language),
      }))
    );
  };
};

/**
 * @typedef Activity
 * @property {number} id The activity id
 * @property {string} name The activity name
 */

/**
 * @typedef ActivitiesAPIResponse
 * @property {Activity[]} activities
 * @property {Activity[]} skills
 * @property {Activity[]} accreditations
 */

/** @type {(jobCode: string, type: ('activities' | 'skills' | 'accreditations'), searchParams: object) => import("react-query").UseQueryResult<Activity[], Error>} */
export const useFetchJobActivitiesQuery = (jobCode, type, searchParams) => {
  const skillAPI = useSkillAPI();
  return useQuery({
    queryKey: [`api/skill/candidate/job/byCode/${jobCode}/skills`, type, searchParams],
    queryFn: async () => {
      const result = await skillAPI
        .get(`api/skill/candidate/job/byCode/${jobCode}/skills`, { searchParams })
        .json();
      if (type === "accreditations") {
        return result.activities.filter((activity) => activity.accreditation === true);
      }
      if (type === "activities") {
        return result.activities.filter((activity) => activity.accreditation === false);
      }
      return result.skills;
    },
    enabled: !!jobCode && !!type,
  });
};