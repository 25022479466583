/** @jsxImportSource @emotion/react */
import {
    CometChatContextProvider,
    CometChatIncomingCall,
    CometChatIncomingDirectCall,
    enums, SoundManager, theme
} from "@jobook-io/jobook-chat";
import { useEffect, useRef } from "react";
import { useAuthenticateCometChatMutation } from "./useAuthenticateCometChatMutation";
import { useCometChatLanguage } from "./useCometChatLanguage";

const ChatIncomingCall = () => {
  /** @typedef {"incomingcall" | "outgoingcall" | "incomingmessage" | "incomingothermessage" | "outgoingmessage"} AudioType */
  SoundManager.loadAudio = async (/** @type {AudioType} */ type) => {
    switch (type) {
      case "incomingcall":
        return (await import("@jobook-io/jobook-chat/dist/incomingcall.wav")).default;
      case "outgoingcall":
        return (await import("@jobook-io/jobook-chat/dist/outgoingcall.wav")).default;
      case "incomingmessage":
        return (await import("@jobook-io/jobook-chat/dist/incomingmessage.wav")).default;
      case "incomingothermessage":
        return (await import("@jobook-io/jobook-chat/dist/incomingothermessage.wav")).default;
      case "outgoingmessage":
        return (await import("@jobook-io/jobook-chat/dist/outgoingmessage.wav")).default;
      default:
        throw new Error(`SoundManager.loadAudio("${type}") is not implemented.`);
    }
  };

  const lang = useCometChatLanguage();
  const { mutate: authenticateCometChat, status } = useAuthenticateCometChatMutation();

  useEffect(() => {
    authenticateCometChat();
  }, [authenticateCometChat]);

  const contextProviderRef = useRef();

  const actionHandler = (action, item, count, ...otherProps) => {
    switch (action) {
      case enums.ACTIONS["TOGGLE_SIDEBAR"]:
        this.toggleSideBar();
        break;
      case enums.GROUP_MEMBER_SCOPE_CHANGED:
      case enums.GROUP_MEMBER_KICKED:
      case enums.GROUP_MEMBER_BANNED:
        this.groupUpdated(action, item, count, ...otherProps);
        break;
      default:
        break;
    }
  };
  return (
    <>
      {status === "success" && (
        <CometChatContextProvider ref={contextProviderRef}>
          <CometChatIncomingCall theme={theme} lang={lang} actionGenerated={actionHandler} tw="top-1" />
          <CometChatIncomingDirectCall theme={theme} lang={lang} actionGenerated={actionHandler} />
        </CometChatContextProvider>
      )}
    </>
  );
};

export default ChatIncomingCall;
