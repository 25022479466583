/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import "twin.macro";

export const JobAdProfileCard = ({
  title,
  description,
  illustration,
  to,
  counter,
  city,
  job,
  ...props
}) => {
  const Component = to ? Link : "div";
  return (
    <Component
      css="min-height:8rem"
      tw="relative rounded-2xl shadow-md transform transition hocus:(shadow-2xl scale-105) px-4 py-4 overflow-hidden"
      to={to}
      {...props}
    >
      <img tw="absolute inset-0 h-full w-full object-cover" src={illustration} alt="" />

      <div tw="relative">
        <h3 tw="flex text-lg leading-6 font-medium text-gray-800"><span>{title}</span>
        <span tw="mx-2 flex items-center justify-center bottom-0 right-0 h-6 w-8 rounded-full ring-2 ring-white font-size[0.8rem] font-medium bg-primary-500 text-white">
          {counter}
        </span></h3>
        <p css="max-width:210px" tw="mt-1 text-sm text-gray-500">{job}</p>
        <p css="max-width:210px" tw="mt-1 text-sm text-gray-500">{city}</p>
      </div>

    </Component>
  );
};
