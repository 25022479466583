/** @jsxImportSource @emotion/react */
import { Global } from "@emotion/react";
import { ErrorBoundary } from "@sentry/react";
import { lazy, Suspense, useEffect } from "react";
import ReactGA from "react-ga";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import "twin.macro";
import { Authenticated, NotAuthenticated } from "./authentication/AuthenticationContext";
import ChatIncomingCall from "./chat/ChatIncomingCall";
import { ErrorAlert } from "./common/ErrorAlert";
import { NoAuthNavigation } from "./common/NoAuthNavigation";
import { PrimaryNavigation } from "./common/PrimaryNavigation";
import { useStoreSearchParam } from "./job-ads/useStoreSearchParam";
import { SkillsFormProvider } from "./skills/SkillsFormContext";
import { WelcomeLayout } from "./welcome/WelcomeLayout";
import { JobAdLayout } from "./unauthenticatedapply/JobAdLayout";

const Welcome = lazy(() => import("./welcome/Welcome"));
const LoginScreen = lazy(() => import("./login/LoginScreen"));
const LoginSuccessScreen = lazy(() => import("./login/LoginSuccessScreen"));
const SignupSuccess = lazy(() => import("./signup/SignupSuccess"));
const LoginWithMagicLinkTokenScreen = lazy(() => import("./welcome/LoginWithMagicLinkTokenScreen"));
const UnauthenticatedApplyToJobAdScreen = lazy(() => import("./unauthenticatedapply/ApplicationScreen"));
const LinkAccountScreen = lazy(() => import("./unauthenticatedapply/LinkAccountScreen"));
const LinkWithMagicLinkTokenScreen = lazy(() => import("./unauthenticatedapply/LinkWithMagicLinkTokenScreen"))

const Home = lazy(() => import("./home/Home"));

const Profile = lazy(() => import("./profile/Profile"));
const ProfileInformation = lazy(() => import("./profile/ProfileInformation"));
const ProfileEducation = lazy(() => import("./profile/ProfileEducation"));
const ProfileAvailability = lazy(() => import("./profile/ProfileAvailability"));
const ProfileMobility = lazy(() => import("./profile/ProfileMobility"));
const ProfileDriverInformation = lazy(() => import("./profile/ProfileDriverInformation"));
const ProfileExperience = lazy(() => import("./profile/ProfileExperience"));
const ProfileExperienceList = lazy(() => import("./profile/ProfileExperienceList"));
const ProfileLanguageList = lazy(() => import("./profile/ProfileLanguageList"));
const ProfileLanguage = lazy(() => import("./profile/ProfileLanguage"));
const ProfilePersonalResume = lazy(() => import("./profile/ProfilePersonalResume"));
const ProfileNewsletter = lazy(() => import("./profile/ProfileNewsletter"));
const ProfileDeleteAccount = lazy(() => import("./profile/ProfileDeleteAccount"));
const ProfileJobInformation = lazy(() => import("./profile/ProfileJobInformation"));
const ProfileShare = lazy(() => import("./profile/ProfileShare"));
const ProfilePublic = lazy(() => import("./profile/ProfilePublic"));

const VideoReader = lazy(() => import("./video/VideoReader"));
const VideoRecorder = lazy(() => import("./video/VideoRecorderRoute"));
const VideoSuccess = lazy(() => import("./video/VideoSuccess"));
const VideoTutorial = lazy(() => import("./video/VideoTutorial"));

const EnrollStudent = lazy(() => import("./school/EnrollStudent"));

const SchoolApplication = lazy(() => import("./school/application/SchoolApplicationCandidate"));

const SpontaneousApplication = lazy(() => import("./spontaneousapplication/SpontaneousApplicationCandidate"));

const QuestionnaireResultsRoute = lazy(() => import("./questionnaire/QuestionnaireResultsRoute"));
const QuestionnairePartRoute = lazy(() => import("./questionnaire/QuestionnairePartRoute"));
const QuestionnairePartResultsRoute = lazy(() =>
  import("./questionnaire/QuestionnairePartResultsRoute")
);
const QuestionnairePartSuccessRoute = lazy(() =>
  import("./questionnaire/QuestionnairePartSuccessRoute")
);
const QuestionnairePartWelcomeRoute = lazy(() =>
  import("./questionnaire/QuestionnairePartWelcomeRoute")
);

const SkillsWelcome = lazy(() => import("./skills/SkillsWelcome"));
const SkillsJobs = lazy(() => import("./skills/SkillsJobs"));
const SkillsActivities = lazy(() => import("./skills/SkillsActivities"));
const SkillsActivitiesHighlight = lazy(() => import("./skills/SkillsActivitiesHighlight"));
const SkillsActivity = lazy(() => import("./skills/SkillsActivity"));
const SkillsSubmit = lazy(() => import("./skills/SkillsSubmit"));
const SkillsSuccess = lazy(() => import("./skills/SkillsSuccess"));
const SkillsResults = lazy(() => import("./skills/SkillsResults"));

const JobAdsScreen = lazy(() => import("./job-ads/JobAdsScreen"));
const JobAdEmptyScreen = lazy(() => import("./job-ads/JobAdEmptyScreen"));
const JobAdExperienceConfirmationScreen = lazy(() =>
  import("./job-ads/JobAdExperienceConfirmationScreen")
);
const JobAdsAppliedScreen = lazy(() => import("./job-ads/JobAdsAppliedScreen"));
const JobAdVisualisationScreen = lazy(() => import("./job-ads/JobAdVisualisationScreen"));

const ChatRoutes = lazy(() => import("./chat/ChatRoutes"));

const Studies = lazy(() => import("./studies/Studies"));
const PhdInfoForm = lazy(() => import("./studies/PhdInfoForm"));
const PhdSubjectForm = lazy(() => import("./studies/PhdSubjectForm"));
const PhdFinanceForm = lazy(() => import("./studies/PhdFinanceForm"));
const PhdcotutelleForm = lazy(() => import("./studies/PhdCotutelleForm"));
const PhdCotutelleUniversity = lazy(() => import("./studies/PhdCotutelleUniversity"));

const PostPhdIsExist = lazy(() => import("./studies/PostPhdIsExist"));
const PostPhdDone = lazy(() => import("./studies/PostPhdDone"));
const PostPhdCNU = lazy(() => import("./studies/PostPhdCNU"));

const Publications = lazy(() => import("./publications/Publications"));

export default function Routes() {
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location]);

  useStoreSearchParam("from-job-ad");
  useStoreSearchParam("utm_source");
  useStoreSearchParam("redirect-to");

  return (
    <ErrorBoundary fallback={(props) => <ErrorAlert {...props} />}>
      <Suspense fallback={null}>
        <Switch>
          {/* Public profile page */}
          <Route path="/public/:userUuid">
            <NoAuthNavigation />
            <Global
              styles={{
                "#root": {
                  overflowX: "hidden",
                },
              }}
            />
            <ErrorBoundary fallback={(props) => <ErrorAlert {...props} />}>
              <Suspense fallback={null}>
                <ProfilePublic />
              </Suspense>
            </ErrorBoundary>
          </Route>

          {/* Login routes */}
          <Route path={["/", "/login", "/login-success", "/sign/:token", "/link-account", "/link/:token"]} exact>
            {/* Welcome, signup, login pages redirects to home page if user is authenticated */}
            <NotAuthenticated fallback={<Redirect to="/home" />}>
              <WelcomeLayout>
                <ErrorBoundary fallback={(props) => <ErrorAlert {...props} />}>
                  <Suspense fallback={null}>
                    <Switch location={location}>
                      <Route path="/" exact component={Welcome} />
                      <Route path="/login" exact component={LoginScreen} />
                      <Route path="/login-success" exact component={LoginSuccessScreen} />
                      <Route path="/sign/:token" exact component={LoginWithMagicLinkTokenScreen} />
                      <Route path="/link-account" exact component={LinkAccountScreen} />
                      <Route path="/link/:token" exact component={LinkWithMagicLinkTokenScreen} />
                    </Switch>
                  </Suspense>
                </ErrorBoundary>
              </WelcomeLayout>
            </NotAuthenticated>
          </Route>

          {/* Unauthenticated application route */}
          {/* <Route path={["/application/:jobAdUuid"]} exact> */}
          {/* no-authentication-apply pages redirects to home page if user is authenticated */}
          {/* <NotAuthenticated fallback={<Redirect to="/home?from-job-ad=:jobAdUuid" />}>
              <JobAdLayout>
                <ErrorBoundary fallback={(props) => <ErrorAlert {...props} />}>
                  <Suspense fallback={null}>
                    <Switch location={location}>
                      <Route path="/application/:jobAdUuid" exact component={UnauthenticatedApplyToJobAdScreen} />
                    </Switch>
                  </Suspense>
                </ErrorBoundary>
              </JobAdLayout>
            </NotAuthenticated>
          </Route> */}
          <Route
            path="/application/:jobAdUuid"
            render={({ match }) => {
              return (
                <NotAuthenticated fallback={<Redirect to={`/home?from-job-ad=${match.params.jobAdUuid}`} />}>
                  <JobAdLayout>
                    <ErrorBoundary fallback={(props) => <ErrorAlert {...props} />}>
                      <Suspense fallback={null}>
                        <Switch location={location}>
                          <Route path="/application/:jobAdUuid" exact component={UnauthenticatedApplyToJobAdScreen} />
                        </Switch>
                      </Suspense>
                    </ErrorBoundary>
                  </JobAdLayout>
                </NotAuthenticated>
              );
            }}
          />

          {/* Authenticated routes redirect to home if not authenticated */}
          <Authenticated
            fallback={
              <Redirect
                to={{
                  pathname: "/",
                  search: `?redirect-to=${encodeURIComponent(location.pathname + location.search)}`,
                }}
              />
            }
          >
            <PrimaryNavigation />
            <Global
              styles={{
                ".callalert__wrapper": {
                  "z-index": "10000 !important",
                },
              }}
            />
            <ChatIncomingCall />
            <ErrorBoundary fallback={(props) => <ErrorAlert {...props} />}>
              <Suspense fallback={null}>
                <Switch>
                  <Route path="/signup/success" exact component={SignupSuccess} />

                  {/* Profile routes */}
                  <Route path="/home" exact component={Home} />
                  <Route path="/profile" exact component={Profile} />
                  <Route path="/profile/information" exact component={ProfileInformation} />
                  <Route path="/profile/education" exact component={ProfileEducation} />
                  <Route path="/profile/availability" exact component={ProfileAvailability} />
                  <Route path="/profile/mobility" exact component={ProfileMobility} />
                  <Route
                    path="/profile/driver-information"
                    exact
                    component={ProfileDriverInformation}
                  />
                  <Route path="/profile/experiences" exact component={ProfileExperienceList} />
                  <Route path="/profile/experiences/:id" exact component={ProfileExperience} />
                  <Route path="/profile/languages" exact component={ProfileLanguageList} />
                  <Route path="/profile/languages/:id" exact component={ProfileLanguage} />
                  <Route path="/profile/job-information" exact component={ProfileJobInformation} />
                  <Route path="/profile/personal-resume" exact component={ProfilePersonalResume} />
                  <Route path="/profile/newsletter" exact component={ProfileNewsletter} />
                  <Route path="/profile/delete-account" exact component={ProfileDeleteAccount} />
                  <Route path="/profile/share" exact component={ProfileShare} />

                  {/* Videos routes */}
                  <Route path="/videos" exact component={VideoReader} />
                  <Route path="/videos/record" exact component={VideoRecorder} />
                  <Route path="/videos/success" exact component={VideoSuccess} />
                  <Route path="/videos/tutorial" exact component={VideoTutorial} />

                  {/* Link a student to a the student school pool*/}
                  <Route path="/partnership" exact component={EnrollStudent} />

                  {/* Link a candidate to a school pool*/}
                  <Route path="/schoolapplication" exact component={SchoolApplication} />

                  {/* Link a candidate to the company application pool*/}
                  <Route path="/companyapplication" exact component={SpontaneousApplication} />

                  {/* Skills routes*/}
                  <Route path="/skills/welcome" exact component={SkillsWelcome} />
                  <Route path="/skills/jobs" exact component={SkillsJobs} />
                  <Route path="/skills/jobs/:jobCode">
                    <SkillsFormProvider>
                      <Switch>
                        <Route path="/skills/jobs/:jobCode/submit" exact component={SkillsSubmit} />
                        <Route
                          path="/skills/jobs/:jobCode/:activityType"
                          exact
                          component={SkillsActivities}
                        />
                        <Route
                          path="/skills/jobs/:jobCode/:activityType/highlight"
                          exact
                          component={SkillsActivitiesHighlight}
                        />
                        <Route
                          path="/skills/jobs/:jobCode/:activityType/:activityId"
                          exact
                          component={SkillsActivity}
                          key={location.pathname}
                        />
                      </Switch>
                    </SkillsFormProvider>
                  </Route>
                  <Route path="/skills/success" exact component={SkillsSuccess} />
                  <Route path="/skills/results" exact component={SkillsResults} />

                  {/* Questionnaire routes */}
                  <Route
                    path="/questionnaire/:questionnaireId"
                    exact
                    component={QuestionnaireResultsRoute}
                  />
                  <Route
                    path="/questionnaire/:questionnaireId/:questionnairePartId/welcome"
                    exact
                    component={QuestionnairePartWelcomeRoute}
                  />
                  <Route
                    path="/questionnaire/:questionnaireId/:questionnairePartId"
                    exact
                    component={QuestionnairePartRoute}
                  />
                  <Route
                    path="/questionnaire/:questionnaireId/:questionnairePartId/success"
                    exact
                    component={QuestionnairePartSuccessRoute}
                  />
                  <Route
                    path="/questionnaire/:questionnaireId/:questionnairePartId/results"
                    exact
                    component={QuestionnairePartResultsRoute}
                  />

                  {/* Jobs routes */}
                  <Route path="/job-ads" exact component={JobAdsScreen} />
                  <Route path="/job-ads/empty" exact component={JobAdEmptyScreen} />
                  <Route
                    path="/job-ads/experience-confirmation"
                    exact
                    component={JobAdExperienceConfirmationScreen}
                  />
                  <Route path="/job-ads/applied" exact component={JobAdsAppliedScreen} />
                  <Route path="/job-ads/applied/:jobAdId" exact component={JobAdVisualisationScreen} />

                  <Route path="/chat" component={ChatRoutes} />

                  {/* Studies routes */}
                  <Route path="/studies" exact component={Studies} />
                  <Route path="/studies/information" exact component={PhdInfoForm} />
                  <Route path="/studies/information/subject" exact component={PhdSubjectForm} />
                  <Route path="/studies/information/finance" exact component={PhdFinanceForm} />
                  <Route path="/studies/information/cotutelle" exact component={PhdcotutelleForm} />
                  <Route
                    path="/studies/information/cotutelle/:isCotutelle"
                    exact
                    component={PhdcotutelleForm}
                  />
                  <Route
                    path="/studies/information/cotutelle/university/add"
                    exact
                    component={PhdCotutelleUniversity}
                  />
                  <Route
                    path="/studies/information/cotutelle/university/edit/:universityName"
                    exact
                    component={PhdCotutelleUniversity}
                  />
                  <Route path="/studies/postphdisexist" exact component={PostPhdIsExist} />
                  <Route path="/studies/postphddone" exact component={PostPhdDone} />
                  <Route path="/studies/postphdcnu" exact component={PostPhdCNU} />

                  {/* Publications routes */}
                  <Route path="/publications" exact component={Publications} />
                </Switch>
              </Suspense>
            </ErrorBoundary>
          </Authenticated>
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
}
