/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import {
  BriefcaseIcon,
  ChatAlt2Icon,
  CollectionIcon,
  AcademicCapIcon,
  UserIcon,
  VideoCameraIcon,
} from "@heroicons/react/solid";
import { BookOpenIcon } from "@heroicons/react/outline";
import { ErrorBoundary } from "@sentry/react";
import { createContext, Suspense, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useRouteMatch } from "react-router-dom";
import "twin.macro";
import tw from "twin.macro";
import { useHasChatEnabled, useUnreadMessageCount } from "../chat/useAuthenticateCometChatMutation";
import {
  useQuestionnaireProgress,
  useSkillsQuestionnaireProgress,
  useStudiesProgress,
  usePublicationsProgress,
} from "../home/ProfileProgression";
import { useMatchedJobAdsQuery, useCountPoleEmploiMatchQuery } from "../job-ads/JobAdsAPI";
import { useFetchProfileDetailsQuery } from "../profile/ProfileAPI";
import { Logo } from "./Logo";
import { useDisclosure } from "./useDisclosure";
import { useFetchProfileQuery } from "../profile/ProfileAPI";
import jobAdsCanApply from "./jobAdsCanApply";

const SkillsIcon = (props) => {
  return (
    <svg viewBox="0 0 48 48" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M41.185 3a4.68 4.68 0 00-4.676 4.676c0 .806.205 1.565.566 2.228l-7.66 7.66a6.189 6.189 0 00-7.632 0l-5.977-5.977c.274-.463.442-.997.442-1.573a3.12 3.12 0 00-3.117-3.118 3.12 3.12 0 00-3.117 3.118 3.12 3.12 0 003.117 3.117c.577 0 1.11-.168 1.573-.442l5.976 5.977a6.197 6.197 0 000 7.633l-9.868 9.87a4.653 4.653 0 00-3.136-1.218A4.68 4.68 0 003 39.627a4.68 4.68 0 004.676 4.676 4.681 4.681 0 004.676-4.676 4.65 4.65 0 00-.566-2.229l9.997-9.997a6.207 6.207 0 003.037 1.262v7.917a4.679 4.679 0 00-3.896 4.606 4.68 4.68 0 004.676 4.676 4.68 4.68 0 004.676-4.676 4.68 4.68 0 00-3.897-4.606v-7.918a6.2 6.2 0 003.038-1.262l5.977 5.976a3.064 3.064 0 00-.443 1.575 3.12 3.12 0 003.117 3.117 3.12 3.12 0 003.117-3.117 3.12 3.12 0 00-3.117-3.118c-.576 0-1.11.168-1.573.442L30.518 26.3a6.196 6.196 0 000-7.633l7.532-7.532a4.65 4.65 0 003.136 1.217 4.68 4.68 0 004.675-4.676A4.68 4.68 0 0041.185 3zm-3.117 4.676a3.12 3.12 0 003.117 3.117 3.12 3.12 0 003.117-3.117 3.12 3.12 0 00-3.117-3.117 3.12 3.12 0 00-3.117 3.117zm-26.495 2.338c0-.86.699-1.559 1.558-1.559.86 0 1.559.699 1.559 1.559s-.7 1.558-1.559 1.558a1.56 1.56 0 01-1.559-1.558zm9.35 12.468a4.681 4.681 0 004.676 4.676 4.68 4.68 0 004.676-4.676 4.68 4.68 0 00-4.676-4.676 4.68 4.68 0 00-4.675 4.676zM39.627 34.95a1.56 1.56 0 01-1.559 1.558 1.56 1.56 0 010-3.117c.86 0 1.559.7 1.559 1.559zM4.559 39.626a3.12 3.12 0 003.117 3.118 3.12 3.12 0 003.117-3.118 3.12 3.12 0 00-3.117-3.117 3.12 3.12 0 00-3.117 3.117zm24.158 1.56a3.121 3.121 0 01-3.118 3.117 3.12 3.12 0 01-3.117-3.118 3.12 3.12 0 013.117-3.117 3.12 3.12 0 013.118 3.117z"
        clipRule="evenodd"
      />
    </svg>
  );
};

const StrengthIcon = (props) => {
  return (
    <svg viewBox="0 0 48 48" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M42.253 18.381a.636.636 0 00.008-.075c0-.029 0-.058-.002-.087a.393.393 0 00-.013-.075c-.006-.028-.016-.056-.025-.084-.006-.015-.007-.029-.014-.044-.005-.011-.013-.018-.019-.028-.005-.011-.008-.021-.014-.031l-6.045-9.673a.61.61 0 00-.097-.118l-.002-.001a.562.562 0 00-.18-.112c-.008-.004-.014-.011-.022-.015-.007-.002-.015-.001-.021-.003A.58.58 0 0035.618 8H12.646a.63.63 0 00-.188.034c-.006.001-.013 0-.018.002-.009.004-.015.011-.023.014a.597.597 0 00-.174.106c-.003.003-.008.005-.01.008a.57.57 0 00-.099.119l-6.045 9.672c-.006.01-.008.021-.014.031-.005.011-.014.019-.019.029-.006.014-.007.029-.014.043-.01.028-.02.056-.026.085a.615.615 0 00.042.392c.005.01.007.022.013.033.01.018.024.031.035.047.008.013.013.027.024.039l17.523 21.754c.001.002.003.003.003.005l.003.004.005.003a.59.59 0 00.18.144c.019.01.036.019.054.026.073.03.151.053.232.053a.608.608 0 00.207-.037c.01-.004.017-.012.027-.017.031-.013.055-.033.083-.051a.574.574 0 00.125-.099c.008-.009.021-.013.029-.022l17.531-21.763c.01-.012.015-.026.023-.039.012-.016.025-.029.035-.047.006-.011.008-.023.013-.033a.46.46 0 00.027-.07.498.498 0 00.023-.084zM16.41 17.147l-2.901-7.938h9.351l-6.45 7.938zm8.991-7.938h9.345l-2.938 7.887-6.407-7.887zm-1.27.356l-6.588 8.108h13.176l-6.588-8.108zm11.623.402l4.816 7.706h-7.686l2.87-7.706zm-20.439 7.705l-2.813-7.698-4.811 7.698h7.624zm.441 1.209l6.552 17.933L7.864 18.881h7.892zm8.269 19.106l7.118-19.106H17.044l6.981 19.106zm8.409-19.106L25.58 37.277l14.819-18.396h-7.965z"
        clipRule="evenodd"
      />
    </svg>
  );
};

const PrimaryNavigationDisclosureContext = createContext();

export const PrimaryNavigationDisclosureProvider = ({ children }) => {
  const state = useDisclosure(true);
  return (
    <PrimaryNavigationDisclosureContext.Provider value={state}>
      {children}
    </PrimaryNavigationDisclosureContext.Provider>
  );
};

export const usePrimaryNavigationDisclosure = () => {
  const context = useContext(PrimaryNavigationDisclosureContext);
  if (context === undefined) {
    throw new Error(
      `\`${usePrimaryNavigationDisclosure.name}\` must be within a \`${PrimaryNavigationDisclosureProvider.name}\`.`
    );
  }
  return context;
};

const Menu = styled("ul")(tw`flex flex-1 space-x-4 sm:(flex-none) justify-around sm:(space-x-8)`);

const MenuItem = styled("li")(tw`-webkit-tap-highlight-color[rgba(0,0,0,0)]`);

const MenuItemLink = ({ to, exact, sensitive, strict, count, icon, children, ...props }) => {
  const isActive = useRouteMatch({
    path: to,
    exact,
    sensitive,
    strict,
  });
  return (
    <Link
      to={to}
      css={[
        tw`relative flex flex-shrink-0 items-center justify-center p-3 rounded-full focus:(outline-none) svg:(w-5 h-5 flex-shrink-0)`,
        isActive ? tw`shadow-md` : tw`shadow-none`,
      ]}
      aria-current={isActive ? "page" : null}
      {...props}
    >
      <span tw="transition" css={isActive ? tw`text-primary-500` : tw`text-gray-300`}>
        {icon}
      </span>
      {count ? (
        <span tw="absolute flex items-center justify-center bottom-0 right-0 h-4 w-4 rounded-full ring-2 ring-white font-size[0.5rem] font-medium bg-primary-500 text-white">
          {count}
        </span>
      ) : null}
      <span tw="sr-only">{children}</span>
    </Link>
  );
};

const SkillsMenuItemFallback = () => {
  const { t } = useTranslation();
  return (
    <MenuItemLink icon={<SkillsIcon />} to="/skills/welcome">
      {t("navigation.Skills")}
    </MenuItemLink>
  );
};

const StudiesMenuItemFallback = () => {
  const { t } = useTranslation();
  return (
    <MenuItemLink icon={<AcademicCapIcon />} to="/studies">
      {t("navigation.Skills")}
    </MenuItemLink>
  );
};

const SkillsMenuItem = () => {
  const { t } = useTranslation();
  const skillsQuestionnaireProgress = useSkillsQuestionnaireProgress();
  return (
    <MenuItemLink icon={<SkillsIcon />} to={skillsQuestionnaireProgress.next}>
      {t("navigation.Skills")}
    </MenuItemLink>
  );
};

const StudiesMenuItem = () => {
  const { t } = useTranslation();
  const sudiesProgress = useStudiesProgress();
  return (
    <MenuItemLink icon={<AcademicCapIcon />} to={sudiesProgress.next}>
      {t("navigation.Skills")}
    </MenuItemLink>
  );
};

const PublicationsMenuItemFallback = () => {
  const { t } = useTranslation();
  return (
    <MenuItemLink icon={<BookOpenIcon />} to="/publications">
      {t("publications.Publications")}
    </MenuItemLink>
  );
};

const PublicationsMenuItem = () => {
  const { t } = useTranslation();
  const publicationsProgress = usePublicationsProgress();
  return (
    <MenuItemLink icon={<BookOpenIcon />} to={publicationsProgress.next}>
      {t("publications.Publications")}
    </MenuItemLink>
  );
};

const PersonnalityMenuItemFallback = () => {
  const { t } = useTranslation();
  return (
    <MenuItemLink icon={<StrengthIcon />} to="/questionnaire/intelli7/part1/welcome">
      {t("navigation.Strengths")}
    </MenuItemLink>
  );
};

const PersonnalityMenuItem = () => {
  const { t } = useTranslation();
  const personnalityQuestionnaireProgress = useQuestionnaireProgress("intelli7");
  return (
    <MenuItemLink icon={<StrengthIcon />} to={personnalityQuestionnaireProgress.next}>
      {t("navigation.Strengths")}
    </MenuItemLink>
  );
};

const enabledMenuItems = JSON.parse(process.env.REACT_APP_MENU_ITEMS);

export const PrimaryNavigation = () => {
  const { t } = useTranslation();
  const { isOpen } = usePrimaryNavigationDisclosure();
  const { data: matchedJobAds } = useMatchedJobAdsQuery();
  const { data: candidateDetails } = useFetchProfileDetailsQuery();
  const { data: counterPoleEmploiAds } = useCountPoleEmploiMatchQuery({
    jobCode: candidateDetails?.job?.code?.substring(0,5) || null,
    latitude: candidateDetails?.mobility?.latitude || null,
    longitude: candidateDetails?.mobility?.longitude || null,
    distance: candidateDetails?.mobility?.radiusInKm ? candidateDetails?.mobility?.radiusInKm : 150
  });
  const { data: candidate } = useFetchProfileQuery();
  const { data: hasChatEnabled } = useHasChatEnabled();
  const { data: unreadMessageCount } = useUnreadMessageCount();
  const menuItems = [
    {
      name: "profile",
      content: (
        <MenuItemLink icon={<UserIcon />} to="/home">
          {t("navigation.Profile")}
        </MenuItemLink>
      ),
    },
    {
      name: "studies",
      content: (
        <ErrorBoundary fallback={() => <StudiesMenuItemFallback />}>
          <Suspense fallback={<StudiesMenuItemFallback />}>
            <StudiesMenuItem />
          </Suspense>
        </ErrorBoundary>
      ),
    },
    {
      name: "publications",
      content: (
        <ErrorBoundary fallback={() => <PublicationsMenuItemFallback />}>
          <Suspense fallback={<PublicationsMenuItemFallback />}>
            <PublicationsMenuItem />
          </Suspense>
        </ErrorBoundary>
      ),
    },
    {
      name: "skills",
      content: (
        <ErrorBoundary fallback={() => <SkillsMenuItemFallback />}>
          <Suspense fallback={<SkillsMenuItemFallback />}>
            <SkillsMenuItem />
          </Suspense>
        </ErrorBoundary>
      ),
    },
    {
      name: "intelli7",
      content: (
        <ErrorBoundary fallback={() => <PersonnalityMenuItemFallback />}>
          <Suspense fallback={<PersonnalityMenuItemFallback />}>
            <PersonnalityMenuItem />
          </Suspense>
        </ErrorBoundary>
      ),
    },
    {
      name: "videos",
      content: (
        <MenuItemLink icon={<VideoCameraIcon />} to="/videos">
          {t("navigation.Videos")}
        </MenuItemLink>
      ),
    },
    hasChatEnabled && {
      name: "chat",
      content: (
        <MenuItemLink icon={<ChatAlt2Icon />} to="/chat" count={unreadMessageCount}>
          {t("navigation.Chat")}
        </MenuItemLink>
      ),
    },
    {
      name: "job-ads",
      content: (
        <MenuItemLink
          icon={<CollectionIcon />}
          to="/job-ads"
          count={jobAdsCanApply(candidate, matchedJobAds)?.length + counterPoleEmploiAds}
          exact
        >
          {t("navigation.Jobs")}
        </MenuItemLink>
      ),
    },
    {
      name: "job-ads-applied",
      content: (
        <MenuItemLink icon={<BriefcaseIcon />} to="/job-ads/applied" exact>
          {t("navigation.JobsApplied")}
        </MenuItemLink>
      ),
    },
  ]
    .filter(Boolean)
    .filter(({ name }) => enabledMenuItems.includes(name));

  return (
    <nav
      css={[
        tw`fixed left-0 right-0 bottom-0 top-auto h-16 sm:(top-0 bottom-auto) bg-white border-t border-gray-200 sm:(border-t-0 border-b) transition-transform`,
        // Leaflet maps are z-index = 1000, we want the primary navigation to go above them
        tw`z-index[2000]`,
        isOpen ? tw`translate-y-0` : tw`translate-y-full`,
        tw`sm:(translate-y-0)`,
      ]}
    >
      <div tw="w-full max-w-6xl h-full mx-auto px-2 sm:(px-4) flex items-center justify-between">
        <Link to="/home" tw="hidden sm:block">
          <Logo tw="h-8 w-auto" />
        </Link>
        <Menu>
          {menuItems.map(({ name, content }) => (
            <MenuItem key={name}>{content}</MenuItem>
          ))}
        </Menu>
      </div>
    </nav>
  );
};
