import styled from "@emotion/styled";
import tw from "twin.macro";

export const AspectRatioPreserver = styled("div")(
  tw`relative w-full`,
  {
    "& > *": tw`absolute inset-0`,
  },
  ({ aspectRatio }) => ({
    "&::before": {
      content: "''",
      display: "block",
      width: "100%",
      paddingTop: `${(1 / aspectRatio) * 100}%`,
    },
  })
);
