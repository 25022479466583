// @ts-check
import { HTTPError } from "ky";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useMediaAPI } from "../query/ky";

/** @type {() => import("react-query").UseMutationResult<void, Error, Blob>} */
export const useUploadVideoMutation = () => {
  const mediaAPI = useMediaAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (video) => {
      const formData = new FormData();
      formData.append("video", video);
      await mediaAPI.put("api/candidate/me/uploadvid", { body: formData });
    },
    onSuccess: () => {
      queryClient.removeQueries("api/candidate/me/video.mp4");
    },
  });
};

/** @type {() => import("react-query").UseQueryResult<Blob, Error>} */
export const useDownloadVideoQuery = () => {
  const mediaAPI = useMediaAPI();
  return useQuery({
    queryKey: "api/candidate/me/video.mp4",
    queryFn: async () => {
      try {
        return await mediaAPI.get("api/candidate/me/video.mp4").blob();
      } catch (error) {
        if (error instanceof HTTPError && error.response.status === 404) {
          return null;
        }
        throw error;
      }
    },
  });
};

/** @type {(userUUID: string) => import("react-query").UseQueryResult<Blob, Error>} */
export const useDownloadVideoPublicQuery = (userUUID) => {
  const mediaAPI = useMediaAPI();
  return useQuery({
    queryKey: `public/candidate/video/${userUUID}.mp4`,
    queryFn: async () => {
      try {
        return await mediaAPI.get(`public/candidate/video/${userUUID}.mp4`).blob();
      } catch (error) {
        if (error instanceof HTTPError && error.response.status === 404) {
          return null;
        }
        if (error instanceof HTTPError && error.response.status === 403) {
          const response = await error.response.json();
          if (
            response.message.startsWith(
              "This customer does not have the right to access this content"
            )
          ) {
            return null;
          }
        }
        throw error;
      }
    },
  });
};

/** @type {(userUUID: string) => string | null} */
export const useRecordedVideoUrlPublic = (userUUID) => {
  const { data: video } = useDownloadVideoPublicQuery(userUUID);
  return video
    ? `${process.env.REACT_APP_MEDIA_API_URL}/public/candidate/video/${userUUID}.mp4`
    : null;
};
