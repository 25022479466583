import { useEffect } from "react";
import { useLocalStorage } from "../common/useLocalStorage";
import { useSearchParams } from "../common/useSearchParams";

/**
 * Store the search parameter with the given key in local storage using the same key.
 * @type {(key: string) => void}
 */
export const useStoreSearchParam = (key) => {
  const { [key]: fromDomain } = useSearchParams();
  const [, setStoredFromDomain] = useLocalStorage(key);
  useEffect(() => {
    if (fromDomain) {
      setStoredFromDomain(decodeURIComponent(fromDomain));
    }
  }, [fromDomain, setStoredFromDomain]);
};
