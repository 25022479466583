// import requireContext from "require-context.macro";
// import { dynamicResource } from "./dynamicResource";
import { useLocalStorage } from "../common/useLocalStorage";
import { usePublicJobAdByIdQuery } from "../job-ads/JobAdsAPI";

// const LogoLightSrc = dynamicResource(requireContext(".", false, /LogoLight(.*)\.svg$/));

export const LogoLight = (props) => {
  const [fromJobAd] = useLocalStorage("from-job-ad");
  const { data: jobAd } = usePublicJobAdByIdQuery(fromJobAd);
  let organizationUniqueUrlName = 'jobfirst'
  if (process.env.REACT_APP_APPLICATION_DOMAIN !== "jobfirst") {
    organizationUniqueUrlName = process.env.REACT_APP_APPLICATION_DOMAIN;
  } else if (jobAd) {
    organizationUniqueUrlName = jobAd.organizationUniqueUrlName;
  }
  const url = `${process.env.REACT_APP_DEV_PUBLIC_MEDIA_URL}/organization/logo/original/${organizationUniqueUrlName}.jpeg`;
  return <img src={url} alt="" {...props} />;
};
