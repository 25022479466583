/** @jsxImportSource @emotion/react */
import { useLocation, useParams  } from "react-router-dom";
import { usePublicJobAdByIdQuery } from "../job-ads/JobAdsAPI";
import "twin.macro";
import tw from "twin.macro";
import { JobAdCardContent } from "./JobAdCardContent";



export const JobAdLayout = ({ children }) => {
  const { jobAdUuid } = useParams();
  const { data: jobAd } = usePublicJobAdByIdQuery(jobAdUuid);
  const location = useLocation();
  return (
    <div tw="flex flex-col sm:(flex-row) min-h-screen bg-[#667af8]">
      {/* Left column with slider */}
      <div tw="w-full sm:w-1/2 sm:max-w-1/2 max-h-screen min-h-screen flex items-center justify-center">
        <JobAdCardContent jobAd={jobAd} />
      </div>
      {/* Right column with layout content. Displayed at the bottom of the page on mobile */}
      <div
        tw="absolute sm:relative w-full flex items-center justify-center sm:w-1/2"
        css={[
          location.pathname === "/"
            ? tw`top-auto bottom-0 min-h-0 bg-transparent`
            : tw`top-0 bottom-auto min-h-screen bg-white`,
          tw`sm:(top-auto bottom-auto bg-white)`,
        ]}
      >
        {children}
      </div>
    </div>
  );
};
