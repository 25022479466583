/** @jsxImportSource @emotion/react */
import { HTTPError, TimeoutError } from "ky";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "twin.macro";
import { isOfflineError } from "../query/utils";
import { Alert } from "./Alert";
import { OfflineState } from "./OfflineState";

function useErrorMessage(error) {
  const [errorMessage, setErrorMessage] = useState(null);
  useEffect(() => {
    if (!error) {
      // If there is no error, do nothing
      return setErrorMessage(null);
    }
    if (error.name === "ChunkLoadError") {
      return setErrorMessage("common.ChunkLoadError");
    }
    if (error.message && !(error instanceof HTTPError)) {
      // If error contains a message, show this message
      return setErrorMessage(error.message);
    }
    if (error instanceof TimeoutError) {
      return setErrorMessage("common.TimeoutError");
    }
    if (!error instanceof HTTPError) {
      // If error is not an HTTPError, we cannot retrieve more information
      return setErrorMessage("common.UnexpectedError");
    }

    // Error is an HTTPError, try to retrieve more information
    (async () => {
      try {
        if (error.response.bodyUsed) {
          // Body is already parsed, we cannot retrieve more information
          return setErrorMessage("common.UnexpectedError");
        }

        // Parse the response body and try to retrieve error details
        const errorJSON = await error.response.json();
        if (errorJSON.message) {
          return setErrorMessage(errorJSON.message);
        }
        if (errorJSON.error) {
          return setErrorMessage(errorJSON.error);
        }
        return setErrorMessage("common.UnexpectedError");
      } catch {
        return setErrorMessage("common.UnexpectedError");
      }
    })();
  }, [error]);
  return errorMessage;
}

export const ErrorAlert = ({ error, resetError, ...props }) => {
  const { t } = useTranslation();
  const errorMessage = useErrorMessage(error);
  if (isOfflineError(error)) {
    // This is a network error (probably no internet access)
    // Display an offline state
    return <OfflineState onRetry={resetError} />;
  }
  return error ? <Alert {...props}>{t(errorMessage)}</Alert> : null;
};
