/** @jsxImportSource @emotion/react */
import { ErrorBoundary } from "@sentry/react";
import { Fragment, Suspense, useMemo } from "react";
import { useTranslation } from "react-i18next";
import requireContext from "require-context.macro";
import "twin.macro";
import { Card } from "../common/Card";
import { dynamicResource } from "../common/dynamicResource";
import {
  useDownloadPictureQuery,
  useFetchProfileDetailsQuery,
  useFetchProfileQuery,
} from "../profile/ProfileAPI";
import { useMatchedJobAdsQuery, useCountPoleEmploiMatchQuery } from "../job-ads/JobAdsAPI";
import { useFetchQuestionnaireResultsQuery } from "../questionnaire/QuestionnaireAPI";
import { useDownloadVideoQuery } from "../video/VideoAPI";
import { ProgressionCard } from "./ProgressionCard";
import { JobAdProfileCard } from "./JobAdProfileCard";
import jobAdsCanApply from "../common/jobAdsCanApply";

const PersonalityProgressionIllustration =
  process.env.REACT_APP_RESOURCE_SUFFIX === "phd"
    ? dynamicResource(requireContext(".", false, /PersonalityPhDProgressionIllustration(.*)\.svg$/))
    : dynamicResource(requireContext(".", false, /PersonalityProgressionIllustration(.*)\.svg$/));

const StudiesProgressionIllustration = dynamicResource(
  requireContext(".", false, /StudiesProgressionIllustration(.*)\.svg$/)
);
const ProfileProgressionIllustration =
  process.env.REACT_APP_RESOURCE_SUFFIX === "phd"
    ? dynamicResource(requireContext(".", false, /ProfilePhDProgressionIllustration(.*)\.svg$/))
    : dynamicResource(requireContext(".", false, /ProfileProgressionIllustration(.*)\.svg$/));

const SkillsProgressionIllustration =
  process.env.REACT_APP_RESOURCE_SUFFIX === "phd"
    ? dynamicResource(requireContext(".", false, /SkillsPhDProgressionIllustration(.*)\.svg$/))
    : dynamicResource(requireContext(".", false, /SkillsProgressionIllustration(.*)\.svg$/));

const VideoProgressionIllustration =
  process.env.REACT_APP_RESOURCE_SUFFIX === "phd"
    ? dynamicResource(requireContext(".", false, /VideoPhDProgressionIllustration(.*)\.svg$/))
    : dynamicResource(requireContext(".", false, /VideoProgressionIllustration(.*)\.svg$/));

const PublicationsProgressionIllustration = dynamicResource(
  requireContext(".", false, /PublicationsProgressionIllustration(.*)\.svg$/)
);

const JobAdProfileIllustration = dynamicResource(
  requireContext(".", false, /ProfileJobOfferIllustration(.*)\.svg$/)
);

export const useAreProfileInformationCompleted = () => {
  const { data: profile } = useFetchProfileQuery();
  const { data: picture } = useDownloadPictureQuery();
  return (
    !!profile.email &&
    // !!profile.birthdate &&
    !!profile.firstname &&
    !!profile.lastname &&
    // !!profile.gender &&
    !!picture
  );
};

export const useIsEducationCompleted = () => {
  const { data: profileDetails } = useFetchProfileDetailsQuery();
  return !!profileDetails.lastDiploma;
};

export const useAreAvailabilitiesCompleted = () => {
  const { data: profileDetails } = useFetchProfileDetailsQuery();
  return !!profileDetails.availabilities;
};

export const useIsMobilityCompleted = () => {
  const { data: profileDetails } = useFetchProfileDetailsQuery();
  return !!profileDetails.mobility;
};

export const useAreWorkExperiencesCompleted = () => {
  const { data: profileDetails } = useFetchProfileDetailsQuery();
  return profileDetails.experiences && profileDetails.experiences.length > 0;
};

export const useAreDriverInformationCompleted = () => {
  const { data: profileDetails } = useFetchProfileDetailsQuery();
  return !!profileDetails.driverLicences;
};

export const useAreSpokenLanguagesCompleted = () => {
  const { data: profileDetails } = useFetchProfileDetailsQuery();
  return profileDetails.spokenLanguages && profileDetails.spokenLanguages.length > 0;
};

export const useArePersonalResumeAdded = () => {
  const { data: profileDetails } = useFetchProfileDetailsQuery();
  return profileDetails.personalResumeExtension;
};


const useProfileProgress = () => {
  const areProfileInformationCompleted = useAreProfileInformationCompleted();
  const isEducationCompleted = useIsEducationCompleted();
  const areAvailabilitiesCompleted = useAreAvailabilitiesCompleted();
  const isMobilityCompleted = useIsMobilityCompleted();
  const areWorkExperiencesCompleted = useAreWorkExperiencesCompleted();
  const areDriverInformationCompleted = useAreDriverInformationCompleted();
  const areSpokenLanguagesCompleted = useAreSpokenLanguagesCompleted();
  // const arePersonalResumeAdded = useArePersonalResumeAdded();

  const total = 7;
  let completed = 0;

  if (areProfileInformationCompleted) {
    completed += 1;
  }
  if (isEducationCompleted) {
    completed += 1;
  }
  if (areAvailabilitiesCompleted) {
    completed += 1;
  }
  if (isMobilityCompleted) {
    completed += 1;
  }
  if (areWorkExperiencesCompleted) {
    completed += 1;
  }
  if (areDriverInformationCompleted) {
    completed += 1;
  }
  if (areSpokenLanguagesCompleted) {
    completed += 1;
  }
  // if (arePersonalResumeAdded) {
  //   completed += 1;
  // }

  return {
    total,
    completed,
    next: "/profile",
  };
};

export const useStudiesProgress = () => {
  const total = 2;
  let completed = 0;

  return {
    total,
    completed,
    next: "/studies",
  };
};

const ProfileProgressionCard = () => {
  const { t } = useTranslation();
  const profileProgress = useProfileProgress();
  return (
    <ProgressionCard
      title={t("home.ProfileProgressionTitle")}
      description={t("home.ProfileProgressionDescription")}
      completed={profileProgress.completed}
      total={profileProgress.total}
      to={profileProgress.next}
      illustration={ProfileProgressionIllustration}
    />
  );
};

const JobAdProgressionCard = ({ city, counter, job }) => {
  const { t } = useTranslation();
  return (
    <JobAdProfileCard
      title={t("home.JobAdTitle")}
      to="/job-ads"
      counter={counter}
      city={city}
      job={job}
      illustration={JobAdProfileIllustration}
    />
  );
};

const StudiesProgressionCard = () => {
  const { t } = useTranslation();
  const studiesProgress = useStudiesProgress();
  return (
    <ProgressionCard
      title={t("home.StudiesProgressionTitle")}
      description={t("home.StudiesProgressionDescription")}
      completed={studiesProgress.completed}
      total={studiesProgress.total}
      to={studiesProgress.next}
      illustration={StudiesProgressionIllustration}
    />
  );
};

export const usePublicationsProgress = () => {
  const total = 1;
  let completed = 0;

  return {
    total,
    completed,
    next: "/publications",
  };
};

const PublicationsProgressionCard = () => {
  const { t } = useTranslation();
  const publicationsProgress = usePublicationsProgress();
  return (
    <ProgressionCard
      title={t("home.PublicationsProgressionTitle")}
      description={t("home.PublicationsProgressionDescription")}
      completed={publicationsProgress.completed}
      total={publicationsProgress.total}
      to={publicationsProgress.next}
      illustration={PublicationsProgressionIllustration}
    />
  );
};

const useVideoProgress = () => {
  const { data: apiVideo } = useDownloadVideoQuery();
  return useMemo(() => {
    const total = 1;
    const completed = apiVideo ? 1 : 0;
    const next = "/videos";
    return {
      total,
      completed,
      next,
    };
  }, [apiVideo]);
};

const VideoProgressionCard = () => {
  const { t } = useTranslation();
  const videoProgress = useVideoProgress();
  return (
    <ProgressionCard
      title={t("home.VideoProgressionTitle")}
      description={t("home.VideoProgressionDescription")}
      completed={videoProgress.completed}
      total={videoProgress.total}
      to={videoProgress.next}
      illustration={VideoProgressionIllustration}
    />
  );
};

export const useQuestionnaireProgress = (questionnaireId) => {
  const { data: questionnaireDto } = useFetchQuestionnaireResultsQuery(questionnaireId);
  return useMemo(() => {
    const { step } = questionnaireDto;
    const total = 3
    let completed = 0;
    let next = null;
    if (step === null) {
      next = `/questionnaire/${questionnaireId}/part1/welcome`;
    } else if (step === 'PART_1') {
      completed = 1;
      next = `/questionnaire/${questionnaireId}/part2/welcome`;
    } else if (step === 'PART_2') {
      completed = 2;
      next = `/questionnaire/${questionnaireId}/part3/welcome`;
    } else {
      // If step = PART_3 or RESULT
      completed = 3;
      next = `/questionnaire/${questionnaireId}`
    }
    return {
      total,
      completed,
      next,
    };
  }, [questionnaireDto, questionnaireId]);
};

const QuestionnaireProgressionCard = () => {
  const { t } = useTranslation();
  const questionnaireProgress = useQuestionnaireProgress("intelli7");
  return (
    <ProgressionCard
      title={t("home.StrengthsProgressionTitle")}
      description={t("home.StrengthsProgressionDescription")}
      completed={questionnaireProgress.completed}
      total={questionnaireProgress.total}
      to={questionnaireProgress.next}
      illustration={PersonalityProgressionIllustration}
    />
  );
};

export const useSkillsQuestionnaireProgress = () => {
  const { data: details } = useFetchProfileDetailsQuery();
  if (!details?.candidateSkills) {
    return {
      total: 1,
      completed: 0,
      next: "/skills/welcome",
    };
  }
  return {
    total: 1,
    completed: 1,
    next: "/skills/results",
  };
};

const SkillsQuestionnaireProgressionCard = () => {
  const { t } = useTranslation();
  const questionnaireProgress = useSkillsQuestionnaireProgress();
  return (
    <ProgressionCard
      title={t("home.SkillsProgressionTitle")}
      description={t("home.SkillsProgressionDescription")}
      completed={questionnaireProgress.completed}
      total={questionnaireProgress.total}
      to={questionnaireProgress.next}
      illustration={SkillsProgressionIllustration}
    />
  );
};

export const ProfileProgression = ({ isPhd }) => {
  const { t } = useTranslation();
  const { data: matchedJobAds } = useMatchedJobAdsQuery();
  const { data: candidateDetails } = useFetchProfileDetailsQuery();
  const { data: candidate } = useFetchProfileQuery();
  let counter = jobAdsCanApply(candidate, matchedJobAds)?.length
  const { data: counterPoleEmploiAds } = useCountPoleEmploiMatchQuery({
    jobCode: candidateDetails?.job?.code?.substring(0, 5) || null,
    latitude: candidateDetails?.mobility?.latitude || null,
    longitude: candidateDetails?.mobility?.longitude || null,
    distance: candidateDetails?.mobility?.radiusInKm ? candidateDetails?.mobility?.radiusInKm : 150
  });
  counter += counterPoleEmploiAds
  const job = candidateDetails?.job?.name;
  return (
    <Card tw="mt-4 grid grid-cols-1 gap-4 sm:(grid-cols-2)">
      {!isPhd && counter != null && counter > 0 && (
        <ErrorBoundary
          fallback={() => (
            <JobAdProfileCard
              title={t("home.JobAdTitle")}
              to="/job-ads"
              counter={counter}
              city={candidateDetails?.mobility?.address}
              job={candidateDetails?.job?.name}
              illustration={JobAdProfileIllustration}
            />
          )}
        >
          <Suspense
            fallback={
              <JobAdProfileCard
                title={t("home.JobAdTitle")}
                to="/job-ads"
                counter={counter}
                city={candidateDetails?.mobility?.address}
                job={candidateDetails?.job?.name}
                illustration={JobAdProfileIllustration}
              />
            }
          >
            <JobAdProgressionCard city={candidateDetails?.mobility?.address} counter={counter}
              job={candidateDetails?.job?.name} />
          </Suspense>
        </ErrorBoundary>
      )}

      <ErrorBoundary
        fallback={() => (
          <ProgressionCard
            title={t("home.ProfileProgressionTitle")}
            description={t("home.ProfileProgressionDescription")}
            completed={0}
            total={7}
            to="/profile"
            illustration={ProfileProgressionIllustration}
          />
        )}
      >
        <Suspense
          fallback={
            <ProgressionCard
              title={t("home.ProfileProgressionTitle")}
              description={t("home.ProfileProgressionDescription")}
              completed={0}
              total={7}
              to="/profile"
              illustration={ProfileProgressionIllustration}
            />
          }
        >
          <ProfileProgressionCard />
        </Suspense>
      </ErrorBoundary>

      {isPhd && (
        <Fragment>
          <ErrorBoundary
            fallback={() => (
              <ProgressionCard
                title={t("home.StudiesProgressionTitle")}
                description={t("home.StudiesProgressionDescription")}
                completed={0}
                total={7}
                to="/studies"
                illustration={StudiesProgressionIllustration}
              />
            )}
          >
            <Suspense
              fallback={
                <ProgressionCard
                  title={t("home.StudiesProgressionTitle")}
                  description={t("home.StudiesProgressionDescription")}
                  completed={0}
                  total={7}
                  to="/studies"
                  illustration={StudiesProgressionIllustration}
                />
              }
            >
              <StudiesProgressionCard />
            </Suspense>
          </ErrorBoundary>

          <ErrorBoundary
            fallback={() => (
              <ProgressionCard
                title={t("home.StudiesProgressionTitle")}
                description={t("home.StudiesProgressionDescription")}
                completed={0}
                total={7}
                to="/studies"
                illustration={StudiesProgressionIllustration}
              />
            )}
          >
            <Suspense
              fallback={
                <ProgressionCard
                  title={t("home.PublicationsProgressionTitle")}
                  description={t("home.PublicationsProgressionDescription")}
                  completed={0}
                  total={7}
                  to="/publications"
                  illustration={PublicationsProgressionIllustration}
                />
              }
            >
              <PublicationsProgressionCard />
            </Suspense>
          </ErrorBoundary>
        </Fragment>
      )}

      <ErrorBoundary
        fallback={() => (
          <ProgressionCard
            title={t("home.SkillsProgressionTitle")}
            description={t("home.SkillsProgressionDescription")}
            completed={0}
            total={1}
            to="/skills/welcome"
            illustration={SkillsProgressionIllustration}
          />
        )}
      >
        <Suspense
          fallback={
            <ProgressionCard
              title={t("home.SkillsProgressionTitle")}
              description={t("home.SkillsProgressionDescription")}
              completed={0}
              total={1}
              to="/skills/welcome"
              illustration={SkillsProgressionIllustration}
            />
          }
        >
          <SkillsQuestionnaireProgressionCard />
        </Suspense>
      </ErrorBoundary>
      <ErrorBoundary
        fallback={() => (
          <ProgressionCard
            title={t("home.StrengthsProgressionTitle")}
            description={t("home.StrengthsProgressionDescription")}
            completed={0}
            total={3}
            to="/questionnaire/intelli7/part1/welcome"
            illustration={PersonalityProgressionIllustration}
          />
        )}
      >
        <Suspense
          fallback={
            <ProgressionCard
              title={t("home.StrengthsProgressionTitle")}
              description={t("home.StrengthsProgressionDescription")}
              completed={0}
              total={3}
              to="/questionnaire/intelli7/part1/welcome"
              illustration={PersonalityProgressionIllustration}
            />
          }
        >
          <QuestionnaireProgressionCard />
        </Suspense>
      </ErrorBoundary>
      <ErrorBoundary
        fallback={() => (
          <ProgressionCard
            title={t("home.VideoProgressionTitle")}
            description={t("home.VideoProgressionDescription")}
            completed={0}
            total={1}
            to="/videos"
            illustration={VideoProgressionIllustration}
          />
        )}
      >
        <Suspense
          fallback={
            <ProgressionCard
              title={t("home.VideoProgressionTitle")}
              description={t("home.VideoProgressionDescription")}
              completed={0}
              total={1}
              to="/videos"
              illustration={VideoProgressionIllustration}
            />
          }
        >
          <VideoProgressionCard />
        </Suspense>
      </ErrorBoundary>
    </Card>
  );
};
