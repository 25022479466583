// @ts-check
import { CometChat } from "@cometchat-pro/chat";
import { useMutation, useQuery } from "react-query";
import { useFetchProfile } from "../profile/ProfileAPI";

const initializeCometChat = async () => {
  const appID = process.env.REACT_APP_COMETCHAT_APP_ID;
  const region = "eu";
  const appSetting = new CometChat.AppSettingsBuilder()
    .subscribePresenceForAllUsers()
    .setRegion(region)
    .build();
  await CometChat.init(appID, appSetting);
};

let loginPromise = null;

export const useAuthenticateCometChatMutation = () => {
  const fetchProfile = useFetchProfile();
  return useMutation({
    mutationFn: async () => {
      await initializeCometChat();
      const user = await fetchProfile();
      if (!user.chatAccessToken) {
        throw new Error("Could not authenticate to chat");
      }

      if (!loginPromise) {
        loginPromise = CometChat.login(user.chatAccessToken);
      }
      await loginPromise;
      loginPromise = null;
    },
  });
};

export const useHasChatEnabled = () => {
  const fetchProfile = useFetchProfile();
  return useQuery({
    queryKey: "hasChatEnabled",
    queryFn: async () => {
      const user = await fetchProfile();
      return !!user.chatAccessToken;
    },
  });
};

export const useUnreadMessageCount = () => {
  const { mutateAsync: authenticateCometChat } = useAuthenticateCometChatMutation();
  return useQuery({
    queryKey: "unreadMessageCount",
    queryFn: async () => {
      try {
        await authenticateCometChat();
        const unreadMessageCount = await CometChat.getUnreadMessageCountForAllUsers();
        return Object.values(unreadMessageCount).reduce((acc, count) => acc + count, 0);
      } catch (error) {
        return 0;
      }
    },
  });
};
