/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import "twin.macro";
import tw from "twin.macro";
import { Logo } from "./Logo";

const NoAuthNavigationWrapper = styled("nav")(
  tw`fixed left-0 right-0 bottom-0 top-auto h-16 sm:(top-0 bottom-auto) bg-white border-t border-gray-200 sm:(border-t-0 border-b) transition-transform`,
  // Leaflet maps are z-index = 1000, we want the primary navigation to go above them
  tw`z-index[2000]`
);

export const NoAuthNavigation = () => {
  return (
    <NoAuthNavigationWrapper>
      <div tw="w-full max-w-6xl h-full mx-auto px-2 sm:(px-4) flex items-center justify-between">
        <Link to="/" tw="hidden sm:block">
          <Logo tw="h-8 w-auto" />
        </Link>
      </div>
    </NoAuthNavigationWrapper>
  );
};
