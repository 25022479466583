/** @jsxImportSource @emotion/react */
import { useLocation } from "react-router-dom";
import tw from "twin.macro";
import { WelcomeSlider } from "./WelcomeSlider";
import { JobAdCardContent } from "../unauthenticatedapply/JobAdCardContent";
import { usePublicJobAdByIdQuery } from "../job-ads/JobAdsAPI";
import { useSearchParams } from "../common/useSearchParams";

export const WelcomeLayout = ({ children }) => {
  const location = useLocation();
  const searchParams = useSearchParams();
  const fromJobAdUUID = searchParams["from-job-ad"]
  const { data: jobAd } = usePublicJobAdByIdQuery(fromJobAdUUID);
  return (
    // <div tw="flex flex-col sm:(flex-row) min-h-screen">
    //   {/* Left column with slider */}
    //   <div tw="w-full sm:w-1/2 min-h-screen flex items-center justify-center">
    //     {
    //       jobAd ? (
    //         <JobAdCardContent jobAd={jobAd} />
    //       ) : (
    //         <WelcomeSlider />
    //       )
    //     }
    //   </div>
    //   {/* Right column with layout content. Displayed at the bottom of the page on mobile */}
    //   <div
    //     tw="absolute sm:relative w-full flex items-center justify-center sm:w-1/2"
    //     css={[
    //       location.pathname === "/"
    //         ? tw`top-auto bottom-0 min-h-0 bg-transparent`
    //         : tw`top-0 bottom-auto min-h-screen bg-white`,
    //       tw`sm:(top-auto bottom-auto bg-white)`,
    //     ]}
    //   >
    //     {children}
    //   </div>
    // </div>



    // <div tw="flex flex-col sm:flex-row min-h-screen">
    //   {/* Div pour WelcomeSlider ou JobAdCardContent */}
    //   <div tw="flex-1 w-full sm:w-1/2 flex items-center justify-center">
    //     {
    //       jobAd ? (
    //         <JobAdCardContent jobAd={jobAd} />
    //       ) : (
    //         <WelcomeSlider />
    //       )
    //     }
    //   </div>

    //   {/* Div pour children */}
    //   <div
    //     tw="z-10 w-full sm:w-1/2 flex items-center justify-center"
    //     css={[
    //       location.pathname === "/"
    //         ? tw`min-h-0 bg-white`
    //         : tw`min-h-screen bg-white`,
    //       tw`sm:(relative min-h-screen bg-white)`,
    //     ]}
    //   >
    //     {children}
    //   </div>
    // </div>

    <div tw="flex flex-col sm:flex-row min-h-screen">
    {/* Div pour WelcomeSlider ou JobAdCardContent */}
    <div tw="w-full sm:flex-1 sm:w-1/2 sm:flex items-center justify-center">
      {
        jobAd ? (
          <JobAdCardContent jobAd={jobAd} />
        ) : (
          <WelcomeSlider />
        )
      }
    </div>

    {/* Div pour children */}
    <div
      tw="z-50 w-full sm:w-1/2 flex items-center justify-center"
      css={[
        location.pathname === "/"
          ? tw`min-h-0 bg-white`
          : tw`min-h-screen bg-white`,
        // !jobAd ? tw`absolute bottom-0` : tw`bg-white`,
        tw`fixed left-0 right-0 bottom-0 overflow-hidden sm:(relative min-h-screen bg-white)`,
      ]}
    >
      {children}
    </div>
  </div>

    // <div tw="flex flex-col sm:flex-row min-h-screen">
    //   {/* Div pour WelcomeSlider ou JobAdCardContent */}
    //   <div tw="flex-grow w-full sm:w-1/2 flex items-center justify-center">
    //     {
    //       jobAd ? (
    //         <JobAdCardContent jobAd={jobAd} />
    //       ) : (
    //         <WelcomeSlider />
    //       )
    //     }
    //   </div>

    //   {/* Div pour children */}
    //   <div
    //     tw="z-10 w-full sm:w-1/2 flex items-center justify-center"
    //     css={[
    //       location.pathname === "/"
    //         ? tw`bg-white`
    //         : tw`bg-white`,
    //       tw`sm:(relative bg-white)`,
    //     ]}
    //   >
    //     {children}
    //   </div>
    // </div>
  );
};
