// @ts-check
import { HTTPError } from "ky";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLanguage } from "../common/useLanguage";
import { useCandidateAPI, useMediaAPI } from "../query/ky";
import { useLogout } from "../authentication/AuthenticationAPI";
import {
  resolveJobTitle,
  useFetchJobByCode,
  useTranslateJobActivityNames,
  useTranslateJobSkillNames,
} from "../skills/SkillsAPI";

/**
 * @typedef Profile
 * @property {string} uuid
 * @property {string} firstname
 * @property {string} lastname
 * @property {'MALE'|'FEMALE'|'OPT_OUT'} gender
 * @property {string} birthdate
 * @property {string} language
 * @property {object} phone
 * @property {string} phone.countryIsoCode
 * @property {string} phone.number
 * @property {string} email
 * @property {string} chatAccessToken
 */

/**
 * Fetch authenticated user profile.
 * @type {() => () => Promise<Partial<Profile>>}
 */
export const useFetchProfile = () => {
  const candidateAPI = useCandidateAPI();
  return async () => {
    try {
      return await candidateAPI.get("api/candidate/me").json();
    } catch (error) {
      // Check if error is a "soft 500" returned by the API
      // This might happen on first connections
      if (error instanceof HTTPError && error.response.status === 500) {
        const response = await error.response.json();
        if (response.message === "No value present") {
          return {};
        }
      }
      throw error;
    }
  };
};

export const useFetchProfileQuery = () => {
  const fetchProfile = useFetchProfile();
  return useQuery({
    queryKey: "api/candidate/me",
    queryFn: fetchProfile,
  });
};

export const useFetchProfilePublicQuery = (userUUID) => {
  const candidateAPI = useCandidateAPI();
  return useQuery({
    queryKey: `public/candidate/publicprofile/${userUUID}`,
    queryFn: async () => {
      try {
        const response = await candidateAPI
          .get(`public/candidate/publicprofile/${userUUID}`)
          .json();
        return response;
      } catch (error) {
        if (error instanceof HTTPError && error.response.status === 403) {
          const response = await error.response.json();
          if (
            response.message.startsWith(
              "This customer does not have the right to access this content"
            )
          ) {
            return null;
          }
        }
      }
    },
    enabled: !!userUUID,
  });
};

/**
 * @typedef UpdateProfilePayload
 * @property {string} firstname - the user firstname
 * @property {string} lastname - the user lastname
 * @property {''|'MALE'|'FEMALE'|'OPT_OUT'} gender - the user gender. If left empty, will set it to undefined.
 * @property {string} birthdate - the user birthdate following this format: yyyy-mm-dd
 * @property {boolean} handicapSituation - the user handicapSituation boolean
 */

/** @type {() => import("react-query").UseMutationResult<void, Error, Partial<UpdateProfilePayload>>} */
export const useUpdateProfileInformationMutation = () => {
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ firstname, lastname, gender, birthdate, handicapSituation }) => {
      await candidateAPI.put("api/candidate/me/info", {
        json: {
          firstname,
          lastname,
          gender: gender === "" ? undefined : gender,
          birthdate,
          handicapSituation
        },
      });
    },
    onSuccess: () => {
      queryClient.removeQueries("api/candidate/me");
    },
  });
};

/**
 * @typedef UpdateNewsletterPayload
 * @property {boolean} newsletterSubscriber - the user newsletterSubscriber boolean
 * @property {boolean} jobAdMailSubscriber - the user jobAdMailSubscriber boolean
 */

/** @type {() => import("react-query").UseMutationResult<void, Error, Partial<UpdateNewsletterPayload>>} */
export const useUpdateNewsletterMutation = () => {
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ newsletterSubscriber, jobAdMailSubscriber }) => {
      await candidateAPI.put("api/candidate/me/subscriber", {
        json: {
          newsletterSubscriber,
          jobAdMailSubscriber
        },
      });
    },
    onSuccess: () => {
      queryClient.removeQueries("api/candidate/me");
    },
  });
};

/**
 * @typedef UpdateEmailPayload
 * @property {string} email - the user email
 */
/** @type {() => import("react-query").UseMutationResult<void, Error, UpdateEmailPayload>} */
const useUpdateProfileEmailMutation = () => {
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ email }) => {
      await candidateAPI.put("api/candidate/me/email", {
        body: email,
      });
    },
    onSuccess: () => {
      queryClient.removeQueries("api/candidate/me");
    },
  });
};

/**
 * @typedef UpdatePhonePayload
 * @property {{ countryIsoCode: string, number: string }} phone - the user phone number information
 */
/** @type {() => import("react-query").UseMutationResult<void, Error, UpdatePhonePayload>} */
const useUpdateProfilePhoneMutation = () => {
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ phone }) => {
      await candidateAPI.put("api/candidate/me/phone", {
        json: phone,
      });
    },
    onSuccess: () => {
      queryClient.removeQueries("api/candidate/me");
    },
  });
};


/** @type {() => import("react-query").UseMutationResult<void, Error, UpdateProfilePayload & UpdateEmailPayload & UpdatePhonePayload>} */
export const useUpdateProfileMutation = () => {
  const { mutateAsync: updateProfileEmail } = useUpdateProfileEmailMutation();
  const { mutateAsync: updateProfilePhone } = useUpdateProfilePhoneMutation();
  const { mutateAsync: updateProfileInformation } = useUpdateProfileInformationMutation();
  return useMutation({
    mutationFn: async (payload) => {
      if (payload.email) {
        await updateProfileEmail(payload);
      }
      if (payload.phone) {
        await updateProfilePhone(payload);
      }
      await updateProfileInformation(payload);
    },
  });
};

/** @type {() => import("react-query").UseQueryResult<string, Error>} */
export const useDownloadPictureQuery = () => {
  const mediaAPI = useMediaAPI();
  return useQuery({
    queryKey: "api/candidate/me/pic.jpeg",
    queryFn: async () => {
      try {
        const blob = await mediaAPI.get("api/candidate/me/pic.jpeg").blob();
        return URL.createObjectURL(blob);
      } catch (error) {
        if (error instanceof HTTPError && error.response.status === 404) {
          return "https://www.gravatar.com/avatar/?d=mp&s=200";
        }
        throw error;
      }
    },
  });
};

/** @type {(userUUID: string) => import("react-query").UseQueryResult<string, Error>} */
export const useDownloadPicturePublicQuery = (userUUID) => {
  const mediaAPI = useMediaAPI();
  return useQuery({
    queryKey: `public/candidate/pic/${userUUID}.jpeg`,
    queryFn: async () => {
      try {
        const blob = await mediaAPI.get(`public/candidate/pic/${userUUID}.jpeg`).blob();
        return URL.createObjectURL(blob);
      } catch (error) {
        if (error instanceof HTTPError && error.response.status === 404) {
          return "https://www.gravatar.com/avatar/?d=mp&s=200";
        }
        throw error;
      }
    },
  });
};

/** @type {() => import("react-query").UseMutationResult<void, Error, Blob>} */
export const useUploadPictureMutation = () => {
  const mediaAPI = useMediaAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (picture) => {
      const formData = new FormData();
      formData.append("image", picture);
      await mediaAPI.put("api/candidate/me/uploadpic", {
        body: formData,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries("api/candidate/me/pic.jpeg");
    },
  });
};

/** @type {() => import("react-query").UseMutationResult<void, Error, Blob>} */
export const useUploadResumeMutation = () => {
  const mediaAPI = useMediaAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (resume) => {
      const formData = new FormData();
      formData.append("file", resume);
      await mediaAPI.put("api/candidate/me/details/uploadpersonalresume", {
        body: formData,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("api/candidate/me/details");
    },
  });
};


/** @type {() => import("react-query").UseMutationResult<>} */
export const useDownloadResumeMutation = () => {
  const mediaAPI = useMediaAPI();
  return useMutation({
    mutationFn: async (extension) => {
      const blob = await mediaAPI.get("api/candidate/me/details/personalresume").blob();
      let link = document.createElement('a');
      link.setAttribute('href', URL.createObjectURL(blob));
      link.setAttribute('download', `personalresume.${extension}`);
      link.click();
      // window.location.href = URL.createObjectURL(blob);
    }
  });
};

export const useDeleteResume = () => {
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (uuid) => {
      await candidateAPI.delete(`api/candidate/me/details/personalresume`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("api/candidate/me/details");
    },
  });
};


/**
 * @typedef ProfileDetails
 * @property {Availabilities} [availabilities] - the candidate availabilities
 * @property {boolean} [employed] - whether or not the candidate is employed
 * @property {Experience[]} [experiences] - the candidate work experiences
 * @property {string} [lastDiploma] - the candidate last diploma
 * @property {Mobility} [mobility] - the candidate mobility
 * @property {DriverInformation} [driverLicences] - the candidate driver information (i.e.: its driving licences and if it has a vehicle)
 * @property {SpokenLanguage[]} [spokenLanguages] - the languages spoken by the candidate
 * @property {import("../skills/SkillsAPI").ActivitiesAPIResponse} [candidateSkills] - the candidate skills
 * @property {CustomTitle} [customResumeTitle] - the candidate custom resume title
 * @property {{ code?: string, name?: string }} [job]
 */

/** @type {() => (jobCode: string, language: import("../common/useLanguage").Language) => Promise<string | null>} */
const useFetchTranslatedJobTitleByJobCode = () => {
  const fetchJobByCode = useFetchJobByCode();
  return async (jobCode, language) => {
    try {
      return resolveJobTitle(await fetchJobByCode(jobCode), language);
    } catch (err) {
      return null;
    }
  };
};

/** @type {() => import("react-query").UseQueryResult<ProfileDetails, Error>} */
export const useFetchProfileDetailsQuery = () => {
  const candidateAPI = useCandidateAPI();
  const language = useLanguage();
  const fetchTranslatedJobTitleByJobCode = useFetchTranslatedJobTitleByJobCode();
  const translateJobSkillNames = useTranslateJobSkillNames();
  const translateJobActivityNames = useTranslateJobActivityNames();
  return useQuery({
    queryKey: "api/candidate/me/details",
    queryFn: async () => {
      const response = await candidateAPI.get("api/candidate/me/details");

      // Check if API did return an empty body.
      // This might happen on first connections if the user did not update its detailed profile.
      // If this is the case, the body is replaced with an empty object.
      const body = await response.text();
      if (body.length === 0) {
        return {};
      }
      const details = /** @type {ProfileDetails} */ (JSON.parse(body));

      // Translate experience job titles if the experiences has a job code.
      if (details.experiences) {
        details.experiences = await Promise.all(
          details.experiences.map(async (experience) => ({
            ...experience,
            title: experience.jobCode
              ? (await fetchTranslatedJobTitleByJobCode(experience.jobCode, language)) ||
              experience.title
              : experience.title,
          }))
        );
      }

      if (details.job && details.job.code) {
        details.job = {
          ...details.job,
          name:
            (await fetchTranslatedJobTitleByJobCode(details.job.code, language)) ||
            // Fallback to job name if the API fails to respond
            details.job.name,
        };
      }

      if (details.candidateSkills) {
        if (details.candidateSkills.skills) {
          details.candidateSkills.skills = await translateJobSkillNames(
            details.candidateSkills.skills,
            language
          );
        }
        if (details.candidateSkills.activities) {
          details.candidateSkills.activities = await translateJobActivityNames(
            details.candidateSkills.activities,
            language
          );
        }
        if (details.candidateSkills.accreditations) {
          details.candidateSkills.accreditations = await translateJobActivityNames(
            details.candidateSkills.accreditations,
            language
          );
        }
      }

      return details;
    },
  });
};

/** @type {() => import("react-query").UseMutationResult<void, Error, string>} */
export const useUpdateDiplomaMutation = () => {
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (diploma) => {
      await candidateAPI.put("api/candidate/me/details/diploma", {
        body: diploma,
      });
    },
    onSuccess: () => {
      queryClient.removeQueries("api/candidate/me/details");
    },
  });
};

/** @typedef {'MONDAY'|'TUESDAY'|'WEDNESDAY'|'THURSDAY'|'FRIDAY'|'SATURDAY'|'SUNDAY'} WeekDay */
/**
 * @typedef DayAvailability
 * @property {boolean} morning
 * @property {boolean} afternoon
 * @property {boolean} evening
 */
/**
 * @typedef {{[key in WeekDay]: DayAvailability}} Availabilities
 */
/** @type {() => import("react-query").UseMutationResult<void, Error, Availabilities>} */
export const useUpdateAvailabilitiesMutation = () => {
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (availabilities) => {
      await candidateAPI.put("api/candidate/me/details/availabilities", {
        json: availabilities,
      });
    },
    onSuccess: () => {
      queryClient.removeQueries("api/candidate/me/details");
    },
  });
};

/**
 * @typedef Mobility
 * @property {number} latitude - the mobility center latitude
 * @property {number} longitude - the mobility center longitude
 * @property {number} radiusInKm - the mobility radius in kilometers
 */
/** @type {() => import("react-query").UseMutationResult<void, Error, Mobility>} */
export const useUpdateMobilityMutation = () => {
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (mobility) => {
      await candidateAPI.put("api/candidate/me/details/mobility", {
        json: mobility,
      });
    },
    onSuccess: () => {
      queryClient.removeQueries("api/candidate/me/details");
    },
  });
};

/**
 * @typedef Experience
 * @property {string} uuid - the work experience id
 * @property {string} company - the experience company name
 * @property {number} companyWorkforce - the experience company workforce
 * @property {string} sector - the work experience sector
 * @property {string} title - the work experience title
 * @property {string} jobCode - the work experience job code
 * @property {string} from - the work experience start data
 * @property {string} to - the work experience end data
 */
/** @type {() => import("react-query").UseMutationResult<void, Error, Experience>} */
export const useUpsertExperienceMutation = () => {
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (experience) => {
      if (experience.uuid) {
        await candidateAPI.put("api/candidate/me/details/experience", { json: experience });
      } else {
        await candidateAPI.post("api/candidate/me/details/experience", { json: experience });
      }
    },
    onSuccess: () => {
      queryClient.removeQueries("api/candidate/me/details");
    },
  });
};

/**
 * @typedef DriverInformation
 * @property {boolean} [hasLicenceA] - whether the candidate has the "A" driving licence or not
 * @property {boolean} [hasLicenceB] - whether the candidate has the "B" driving licence or not
 * @property {boolean} [hasLicenceC] - whether the candidate has the "C" driving licence or not
 * @property {boolean} [hasLicenceCE] - whether the candidate has the "CE" driving licence or not
 * @property {boolean} [hasLicenceC1] - whether the candidate has the "C1" driving licence or not
 * @property {boolean} [hasLicenceC1E] - whether the candidate has the "C1E" driving licence or not
 * @property {boolean} [hasLicenceD] - whether the candidate has the "D" driving licence or not
 * @property {boolean} [hasLicenceDE] - whether the candidate has the "DE" driving licence or not
 * @property {boolean} [hasLicenceD1] - whether the candidate has the "D1" driving licence or not
 * @property {boolean} [hasLicenceD1E] - whether the candidate has the "D1E" driving licence or not
 * @property {boolean} [hasVehicle] - whether the candidate has a vehicle or not
 */
/** @type {() => import("react-query").UseMutationResult<void, Error, DriverInformation>} */
export const useUpdateDriverInformationMutation = () => {
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (driverInformation) => {
      await candidateAPI.put("api/candidate/me/details/driver", {
        json: driverInformation,
      });
    },
    onSuccess: () => {
      queryClient.removeQueries("api/candidate/me/details");
    },
  });
};

/**
 * @typedef SpokenLanguage
 * @property {string} languageIsoCode - the language ISO-639-1 code
 * @property {("levelA" | "levelB" | "levelC" | "levelA1" | "levelA2" | "levelB1" | "levelB2" | "levelC1" | "levelC2")} level - the candidate level at this language
 */
/** @type {() => import("react-query").UseMutationResult<void, Error, SpokenLanguage[]>} */
export const useUpdateLanguagesMutation = () => {
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (spokenLanguages) => {
      await candidateAPI.put("api/candidate/me/details/languages", {
        json: spokenLanguages,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("api/candidate/me/details");
    },
  });
};

/**
 * @typedef CustomTitle
 * @property {string} [fallback] - the title to use if a language specific-one was not found
 * @property {{[key: string]: string}} [labels] - the language specific titles indexed by language key
 */

/** @type {() => import("react-query").UseMutationResult<void, Error, CustomTitle>} */
export const useUpdateCustomTitleMutation = () => {
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (customTitle) => {
      await candidateAPI.put("api/candidate/me/details/title", {
        json: customTitle,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("api/candidate/me/details");
    },
  });
};

/**
 * @typedef SharingOptions
 * @property {boolean} [sharePublicProfile] - share public profile option
 * @property {boolean} [shareVideo] - share video option
 * @property {boolean} [shareIntelli7] - share video option
 */

/** @type {() => import("react-query").UseQueryResult<SharingOptions>} */
export const useFetchSharingOptionsQuery = () => {
  const candidateAPI = useCandidateAPI();
  return useQuery({
    queryKey: "api/candidate/me/share",
    queryFn: async () => candidateAPI.get("api/candidate/me/share").json(),
  });
};

/** @type {() => import("react-query").UseMutationResult<void, Error, SharingOptions>} */
export const useUpdateSharingOptionsMutation = () => {
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (sharingOptions) => {
      await candidateAPI.put("api/candidate/me/share", {
        json: sharingOptions,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("api/candidate/me/share");
    },
  });
};

/** @type {() => import("react-query").UseMutationResult<void, Error, { code: string, name: string}>} */
export const useUpdateJobMutation = () => {
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (job) => {
      await candidateAPI.put("api/candidate/me/details/job", {
        json: job,
      });
    },
    onSuccess: () => {
      queryClient.removeQueries("api/candidate/me/details");
    },
  });
};

export const useDeleteExperience = () => {
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (uuid) => {
      await candidateAPI.delete(`api/candidate/me/details/experience/${uuid}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("api/candidate/me/details");
    },
  });
};

export const useDeleteAccount = () => {
  const candidateAPI = useCandidateAPI();
  const logout = useLogout();
  return useMutation({
    mutationFn: async () => {
      await candidateAPI.delete(`api/candidate/me/delete`);
    },
    onSuccess: () => {
      logout();
    },
  });
};

export const usePhoneCandidateExistQuery = (phone) => {
  const api = useCandidateAPI();
  return useQuery({
    queryKey: `public/application/isPhoneNumberExisting`,
    queryFn: async () => {
      try {
        const data = await api.post(`public/application/isPhoneNumberExisting`, {json: phone}).json();
        return data;
      } catch (error) {
        throw error;
      }
    },
    enabled: false
  });
};
