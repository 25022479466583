import { usePublicJobAdByIdQuery } from "../job-ads/JobAdsAPI";
import { useLocalStorage } from "./useLocalStorage";

/** @type {() => string | null} */
export const useJobAdOrganizationName = () => {
  const [fromJobAd] = useLocalStorage("from-job-ad");
  const { data: jobAd } = usePublicJobAdByIdQuery(fromJobAd);
  const organizationName = jobAd ? jobAd.organizationName : null;
  return organizationName;
};
