// @ts-check
import { useMutation, useQueryClient, useQuery } from "react-query";
import { useCandidateAPI } from "../query/ky";
import { useMemo, useState } from "react";
import UAParser from "ua-parser-js";
import { useLanguage } from "../common/useLanguage";
import { doLogin, useAuthenticationDispatch } from "../authentication/AuthenticationContext";
import { useHistory } from "react-router-dom";
import { useLocalStorage } from "../common/useLocalStorage";
import { storeAuthentication } from "../authentication/AuthenticationAPI";

/** @type {() => import("react-query").UseMutationResult<void, Error>} */
export const usePublicUnauthenticateApplyMutation = () => {
  const candidateAPI = useCandidateAPI();
  const queryClient = useQueryClient();
  const domain = process.env.REACT_APP_APPLICATION_DOMAIN;
  return useMutation({
    mutationFn: async (profile) => {
      profile.domainUniqueName = domain;
      const uuid = await candidateAPI.post(`public/candidate/unauthenticatedapply`, {
        json: profile,
      }).json();
      return uuid;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(`public/candidate/unauthenticatedapply`);
    },
  });
};

const useUserAgent = () => {
  const [parser] = useState(() => new UAParser());
  return useMemo(() => parser.getResult(), [parser]);
};

/** @type {() => (string)} */
const useDeviceType = () => {
  const ua = useUserAgent();
  return ua.device.type;
};

/** @typedef {"MOBILE" | "LAPTOP"} CandidateAuthentication */
/** @type {() => CandidateAuthentication} */
export const useCandidateAuthentication = () => {
  const deviceType = useDeviceType();
  switch (deviceType) {
    case "tablet":
    case "mobile":
      return "MOBILE";
    default:
      return "LAPTOP";
  }
};


/** @typedef {{ token: string }} AuthenticationResult */

/** @type {() => import("react-query").UseMutationResult<void, Error>} */
export const useUnauthenticatedLoginMutation = () => {
  const candidateAPI = useCandidateAPI();
  const language = useLanguage();
  const candidateAuthentication = useCandidateAuthentication();
  const domainUniqueName = process.env.REACT_APP_APPLICATION_DOMAIN !== "jobfirst" ? process.env.REACT_APP_APPLICATION_DOMAIN : null;
  // If candidateAuthentication is MOBILE, find "from-job-ad in localstorage and send it back by sms to keep context"
  return useMutation({
    mutationFn: async ({ phoneNumber, uuid }) => {
      const { isValidPhoneNumber, parsePhoneNumber } = await import("react-phone-number-input");
      if (!isValidPhoneNumber(phoneNumber)) {
        throw new Error("login.InvalidPhoneNumber");
      }
      const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
      if (parsedPhoneNumber === undefined) {
        throw new Error("login.InvalidPhoneNumber");
      }
      if (parsedPhoneNumber.country === undefined) {
        throw new Error("login.InvalidCountryCodeNumber");
      }

      await candidateAPI
        .post("public/candidate/registerorloginunauthenticated", {
          json: {
            language,
            candidateAuthentication,
            uuid,
            domainUniqueName: domainUniqueName
          },
        });
        
    },
  });
};

/** @type {() => (result: AuthenticationResult) => Promise<void>} */
const useAuthenticationSuccessHandler = () => {
  const history = useHistory();
  const dispatch = useAuthenticationDispatch();
  const [redirectTo, setRedirectTo] = useLocalStorage("redirect-to");

  return async (result) => {
    storeAuthentication(result);
    dispatch(doLogin(result));

    // If we previously stored a redirection path, redirect user to it
    if (redirectTo) {
      setRedirectTo(null);
      return history.replace(redirectTo);
    }

    // Otherwise redirect to root
    return history.replace("/");
  };
};

/** @type {() => import("react-query").UseMutationResult<AuthenticationResult, Error, { token: string }>} */
export const useUnauthenticatedConfirmMutation = () => {
  const candidateAPI = useCandidateAPI();
  const authenticationSuccessHandler = useAuthenticationSuccessHandler();
  return useMutation({
    mutationFn: async (json) => candidateAPI.post("public/candidate/confirmunauthenticated", { json }).json(),
    onSuccess: async (result) => {
      await authenticationSuccessHandler(result);
    },
  });
};

/** @type {() => import("react-query").UseMutationResult<AuthenticationResult, Error, { sign: string }>} */
export const useUnauthenticatedLoginWithTokenMutation = () => {
  const authenticationSuccessHandler = useAuthenticationSuccessHandler();
  const candidateAPI = useCandidateAPI();
  return useMutation({
    mutationFn: async (searchParams) =>
      candidateAPI.get("public/candidate/login2unauthenticated", { searchParams }).json(),
    onSuccess: async (result) => {
      await authenticationSuccessHandler(result);
    },
  });
};

export const useValidateCaptchaQuery = (token) => {
  const api = useCandidateAPI();
  return useQuery({
    queryKey: `public/application/validate-captcha`,
    queryFn: async () => {
      try {
        const data = await api.post(`public/application/validate-captcha`, {json: token}).json();
        return data;
      } catch (error) {
        throw error;
      }
    },
    enabled: false
  });
};

