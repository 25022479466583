import { Global } from "@emotion/react";
import { Suspense } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { I18nextProvider, useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import tw, { GlobalStyles } from "twin.macro";
import { AuthenticationProvider } from "./authentication/AuthenticationContext";
import { PrimaryNavigationDisclosureProvider } from "./common/PrimaryNavigation";
import i18n from "./i18n";
import Routes from "./Routes";
import ScrollToTop from "./ScrollToTop";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      retry: false,
    },
  },
});

const SEO = () => {
  const { t } = useTranslation();
  return (
    <Helmet>
      <title>{process.env.REACT_APP_APPLICATION_NAME}</title>
      <meta
        name="description"
        content={t("common.MetaDescription", {
          applicationName: process.env.REACT_APP_APPLICATION_NAME,
        })}
      />
      <meta name="apple-mobile-web-app-title" content={process.env.REACT_APP_APPLICATION_NAME} />
      <meta name="application-name" content={process.env.REACT_APP_APPLICATION_NAME} />
      <meta name="theme-color" content="#ffffff" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`/${process.env.REACT_APP_FAVICONS}/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`/${process.env.REACT_APP_FAVICONS}/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`/${process.env.REACT_APP_FAVICONS}/favicon-16x16.png`}
      />
      <link rel="manifest" href={`/${process.env.REACT_APP_FAVICONS}/manifest.json`} />
    </Helmet>
  );
};

export default function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <GlobalStyles />
      <Global styles={{ body: tw`bg-gray-100` }} />
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <AuthenticationProvider>
            <Suspense fallback={null}>
              <SEO />
            </Suspense>
            <Router>
              <ScrollToTop />
              <PrimaryNavigationDisclosureProvider>
                <Routes />
              </PrimaryNavigationDisclosureProvider>
            </Router>
          </AuthenticationProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </I18nextProvider>
  );
}
